import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './handles.component';

type CauseNodeData = BaseNodeData;
export type CauseNodeType = Node<CauseNodeData>;
type CauseNodeProps = NodeProps<CauseNodeType>;

const className = 'bt-bg-causes-box-bg bt-border-causes-box-border bt-text-causes-box-fg';
const iconClassName = 'bt-text-causes-box-bg bt-stroke-causes-box-fg';
const handles: HandlesProps = { rightHandleId: 'right', rightHandleType: 'source' };
const staticData = { className, iconClassName, handles, showLinkActionIcon: true };

/**
 * A component that represents a cause node in a flow diagram.
 * Extends the base node component with cause-specific functionality.
 *
 * @component
 * @param {CauseNodeProps} props - The properties for the cause node component
 * @returns {JSX.Element} A rendered cause node component
 *
 * @example
 * ```tsx
 * <CauseNode
 *   id="cause-1"
 *   data={{ label: "Root Cause" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const CauseNode = (props: CauseNodeProps): JSX.Element => {
  const data = useMemo(() => {
    return { ...props.data, ...staticData };
  }, [props.data]);

  return <BaseNode {...props} data={data} />;
};

export default CauseNode;
