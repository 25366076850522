import Controls, { ContainerType } from '../controls.component';
import LoadingPreview from '../loading-preview';

interface MitigatingControlsProps {
  isLoading: boolean;
}

const message = 'Mitigating controls will be displayed here';

const MitigatingControls = ({ isLoading }: MitigatingControlsProps) => {
  return (
    <Controls id="mitigating-controls-container" type={ContainerType.MITIGATING_CONTROLS}>
      {isLoading ? <LoadingPreview message={message} /> : message}
    </Controls>
  );
};

export default MitigatingControls;
