import { useUser } from '@myosh/myosh-login';
import { useEffect, useRef } from 'react';
import { AIAnalysisSuggestionContainerRef } from '../../components/common/ai-analysis-suggestion-container';
import { useConfigurationContext } from '../../context/configuration.context';
import { useAppDispatch } from '../../modules/hooks';
import userActions from '../../modules/user/userActions';

function useMainHooks() {
  const analysisRef = useRef<AIAnalysisSuggestionContainerRef>(null);
  const isAIGeneratedRef = useRef(false);
  const isNewDiagramRef = useRef(true);

  const bowtieConfiguration = useConfigurationContext();
  const authUserState = useUser();
  const dispatch = useAppDispatch();

  const onAIAnalysisReady = (text: string) => {
    analysisRef.current?.show(text);
  };

  // TODO: need to review this once more
  useEffect(() => {
    if (!!authUserState?.state?.user) {
      dispatch(userActions.doFetchAuthenticatedUserAndSetPermissions(authUserState.state.user, bowtieConfiguration));
    }
  }, [authUserState?.state?.user]);

  return {
    analysisRef,
    isAIGeneratedRef,
    isNewDiagramRef,
    onAIAnalysisReady,
  };
}

export default useMainHooks;
