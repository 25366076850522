import { useState } from 'react';
import { cn } from '../../../../helpers/util';
import { ControlPayload } from '../../../../modules/diagram/controlActions';
import diagramActions from '../../../../modules/diagram/diagramActions';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import filterSelectors from '../../../../modules/filter/filterSelectors';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import { BowtieBasicRecord, BowtieCauseRecord, BowtieConsequenceRecord } from '../../../../services/bowtie-data-types';
import { ContainerType } from '../controls.component';
import Rectangle, { LINE_DIRECTION } from '../rectangle/rectangle';
import { CONTAINER_ALIGNMENT } from './multi-line-container';
import TextArea from './text-area.component';

export enum CriticalControlType {
  CRITICAL,
  NON_CRITICAL,
}

export enum EffectiveControlType {
  EFFECTIVE,
  NON_EFFECTIVE,
}

// MultiElementLine component
export interface MultiElementLineType {
  id: string; // used as react element key only
  parentId: number;
  childControls: Array<BowtieConsequenceRecord> | Array<BowtieCauseRecord>;
}

interface MultiElementLineProps {
  id: string;
  type: ContainerType;
  multiLines: MultiElementLineType;
  alignment: string;
  index: number;
  formId: number;
}

interface RectangleTextAreaProps {
  index: number;
  line: BowtieBasicRecord;
  lineDirection?: string;
}

interface MultiElementLineRectangleProps {
  id: string;
  line: BowtieBasicRecord;
  lineDirection?: string;
  editable?: boolean;
}

const MultiElementLine = ({ id, type, multiLines, alignment, index, formId }: MultiElementLineProps) => {
  const lines = multiLines.childControls ?? [];

  const dispatch = useAppDispatch();

  const criticalFilterValues = useAppSelector(filterSelectors.selectCriticalFilterValues);
  const effectiveFilterValues = useAppSelector(filterSelectors.selectNonEffectiveFilterValues);

  const userWithPermissions = useAppSelector(userSelectors.selectUser);
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);

  const [elementToEdit, setElementToEdit] = useState<number>();

  const addElement = (payload: { id: string; value: string }) => {
    const obj = {
      ...payload,
      parentId: multiLines.parentId,
    };

    dispatch(
      diagramActions.doAddInQueue({
        payload: obj,
        type: id,
      })
    );
  };

  const removeElement = (payload: { id: string; parentId: number }) => {
    if (id === 'preventative_control') {
      dispatch(diagramActions.doRemovePreventativeControl(payload));
    } else {
      dispatch(diagramActions.doRemoveMitigatingControl(payload));
    }
  };

  const handleClickRectangle = (lineId: number | undefined) => {
    if (userWithPermissions?.permissions?.editRecord?.controls) {
      setElementToEdit(lineId);
    }
  };

  const renderRectangleTextArea = ({ line, index, lineDirection = LINE_DIRECTION.TOP }: RectangleTextAreaProps) => {
    return (
      <TextArea
        key={`${line.id}_${index}`}
        index={index}
        id={id}
        elementId={line.id}
        addElement={addElement}
        removeElement={removeElement}
        lineDirection={lineDirection}
        parentId={multiLines.parentId}
        defaultValue={line.value}
        line={line}
        setElementToEdit={setElementToEdit}
        formId={formId}
      />
    );
  };

  const renderMultiElementLineRectangle = ({
    line,
    id,
    lineDirection = LINE_DIRECTION.TOP,
    editable = false,
  }: MultiElementLineRectangleProps) => {
    const criticalControlType = resolveCriticalControlType(line, criticalFilterValues);
    const effectiveControlType = resolveEffectiveControlType(line, effectiveFilterValues);

    const handleClick = () => handleClickRectangle(line.id);

    return (
      <Rectangle
        id={id}
        type={type}
        link={line.linkUrl}
        text={line.value}
        status={line.status}
        hasLine={true}
        editable={editable}
        lineDirection={lineDirection}
        form={bowtieData.bowtieConfiguration.controls.form}
        rawElement={{ ...line, parentId: multiLines.parentId } as ControlPayload}
        removeElement={removeElement}
        click={handleClick}
        criticalControlType={criticalControlType}
        effectiveControlType={effectiveControlType}
      />
    );
  };

  function renderSingleDirectionRectangleContainer() {
    return (
      <div className="bt-flex bt-h-[188px] bt-flex-col-reverse bt-justify-between bt-pb-[30px]">
        <div
          className={cn(
            'bt-flex',
            { 'bt-flex-row': alignment === CONTAINER_ALIGNMENT.LEFT },
            { 'bt-flex-row-reverse': alignment !== CONTAINER_ALIGNMENT.LEFT }
          )}
        >
          {lines &&
            lines.map((line: BowtieBasicRecord, i: number) => {
              if (!line.value || elementToEdit === line.id) {
                return (
                  <div className="bt-mx-4" key={`${id}_${i}_edit`}>
                    {renderRectangleTextArea({ line: line, index: i })}
                  </div>
                );
              }

              if (i + 1 === lines.length) {
                return (
                  <div className="bt-mx-4" key={i}>
                    {renderMultiElementLineRectangle({ line: line, id: `final_${id}_${index + 1}` })}
                  </div>
                );
              }

              return (
                <div className="bt-mx-4" id={line.value as string} key={i}>
                  {renderMultiElementLineRectangle({ line: line, id: line.value })}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  function renderMultiDirectionRectangleContainer() {
    return (
      <div className="bt-flex bt-h-[188px] bt-flex-col bt-justify-between bt-pb-[30px]" key={index}>
        <div
          className={cn(
            'bt-flex',
            { 'bt-flex-row': alignment === CONTAINER_ALIGNMENT.LEFT },
            { 'bt-flex-row-reverse': alignment !== CONTAINER_ALIGNMENT.LEFT }
          )}
        >
          {lines.map((line: BowtieBasicRecord, i: number) => {
            if (i % 2 === 0) {
              if (!line.value || elementToEdit === line.id) {
                return (
                  <div className="bt-mx-4" key={`${id}_${i}_edit`}>
                    {renderRectangleTextArea({ line: line, index: i, lineDirection: LINE_DIRECTION.BOTTOM })}
                  </div>
                );
              }
              if (i + 1 === lines.length) {
                return (
                  <div className="bt-mx-4" key={i}>
                    {renderMultiElementLineRectangle({
                      line: line,
                      id: `final_${id}_${index + 1}`,
                      lineDirection: LINE_DIRECTION.BOTTOM,
                      editable: line.value === null ? true : false,
                    })}
                  </div>
                );
              }
              return (
                <div className="bt-mx-4" key={i} id={line.value}>
                  {renderMultiElementLineRectangle({
                    line: line,
                    id: line.value,
                    lineDirection: LINE_DIRECTION.BOTTOM,
                  })}
                </div>
              );
            }
            return <div className="bt-mx-4" key={i}></div>;
          })}
        </div>

        <div
          className={cn(
            'bt-flex',
            { 'bt-flex-row': alignment === CONTAINER_ALIGNMENT.LEFT },
            { 'bt-flex-row-reverse': alignment !== CONTAINER_ALIGNMENT.LEFT }
          )}
        >
          {lines.map((line: BowtieBasicRecord, i: number) => {
            if (i % 2 !== 0) {
              if (line.value === null || elementToEdit === line.id) {
                return (
                  <div className="bt-mx-4" key={`${id}_${i}_edit`}>
                    {renderRectangleTextArea({ line: line, index: i })}
                  </div>
                );
              }
              if (i + 1 === lines.length) {
                return (
                  <div className="bt-mx-4" key={i}>
                    {renderMultiElementLineRectangle({
                      line: line,
                      id: `final_${id}_${index + 1}`,
                    })}
                  </div>
                );
              }
              return (
                <div className="bt-mx-4" key={i} id={line.value}>
                  {renderMultiElementLineRectangle({
                    line: line,
                    id: line.value,
                  })}
                </div>
              );
            }
            return <div className="bt-mx-4 bt-w-[120px]" key={i} />;
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="last:bt-mb-[47px]" key={index}>
      {lines && lines.length <= 2
        ? renderSingleDirectionRectangleContainer()
        : renderMultiDirectionRectangleContainer()}
    </div>
  );
};

// Util

// Returns the effective control type or undefined if it cannot be determined
const resolveEffectiveControlType = (
  line: BowtieBasicRecord,
  effectiveFilterValues: { truthy: string; falsy: string }
) => {
  if (line.effectiveOrNotEffective?.value && effectiveFilterValues) {
    if (line.effectiveOrNotEffective.value === effectiveFilterValues.truthy) {
      return EffectiveControlType.EFFECTIVE;
    }

    if (line.effectiveOrNotEffective.value === effectiveFilterValues.falsy) {
      return EffectiveControlType.NON_EFFECTIVE;
    }
  }

  return undefined;
};

// Returns the critical control type or undefined if it cannot be determined
const resolveCriticalControlType = (
  line: BowtieBasicRecord,
  criticalFilterValues: { truthy: string; falsy: string }
) => {
  if (line.criticalOrNonCritical && criticalFilterValues) {
    if (line.criticalOrNonCritical === criticalFilterValues.truthy) {
      return CriticalControlType.CRITICAL;
    }

    if (line.criticalOrNonCritical === criticalFilterValues.falsy) {
      return CriticalControlType.NON_CRITICAL;
    }
  }

  return undefined;
};

export default MultiElementLine;
