import { BowtieStateData } from '../../../../services/bowtie-data-types';
import { ContainerType } from '../controls.component';
import GeneralContainer from '../general-container';
import MultiLineContainer, { CONTAINER_ALIGNMENT } from '../multi-line-container/multi-line-container';

interface CausesExistingProps {
  bowtieData: BowtieStateData;
}

const CausesExisting = ({ bowtieData }: CausesExistingProps) => {
  return (
    <GeneralContainer id="causes-container" type={ContainerType.CAUSES}>
      <MultiLineContainer
        id="causes"
        type={ContainerType.CAUSES}
        mode="single"
        formId={bowtieData.bowtieConfiguration.causes.formId}
        controlLines={bowtieData.causes}
        alignment={CONTAINER_ALIGNMENT.LEFT}
      />
    </GeneralContainer>
  );
};

export default CausesExisting;
