import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import { CriticalControlEnum, EffectiveControlEnum } from '../../util/node-util';
import BaseNode, { BaseNodeData } from './base-node.component';

type MitigatingControlNodeData = BaseNodeData & {
  criticalControlType?: CriticalControlEnum;
  effectiveControlType?: EffectiveControlEnum;
};
export type MitigatingControlNodeType = Node<MitigatingControlNodeData>;
type MitigatingControlNodeProps = NodeProps<MitigatingControlNodeType>;

const defaultClassName = 'bt-bg-mc-default-bg bt-border-mc-default-border bt-text-mc-default-fg';
const nonEffectiveClassName =
  'bt-bg-mc-non_critical-non_effective-bg bt-border-mc-non_critical-non_effective-border bt-text-mc-non_critical-non_effective-fg';
const criticalClassName =
  'bt-bg-mc-critical-effective-bg bt-border-mc-critical-effective-border bt-text-mc-critical-effective-fg';

const defaultIconClassName = 'bt-text-mc-default-bg bt-stroke-mc-default-fg';
const nonEffectiveIconClassName = 'bt-text-mc-non_critical-non_effective-bg bt-stroke-mc-non_critical-non_effective-fg';
const criticalIconClassName = 'bt-text-mc-critical-effective-bg bt-stroke-mc-critical-effective-fg';

const defaultGlobalIconClassName = 'bt-text-mc-default-border';
const nonEffectiveGlobalIconClassName = 'bt-text-mc-non_critical-non_effective-border';
const criticalGlobalIconClassName = 'bt-text-mc-critical-effective-border';

/**
 * A component that represents a mitigating control node in a flow diagram.
 * Extends the base node component with mitigating-control-specific functionality.
 *
 * @component
 * @param {MitigatingControlNodeProps} props - The properties for the mitigating control node component
 * @returns {JSX.Element} A rendered mitigating control node component
 *
 * @example
 * ```tsx
 * <MitigatingControlNode
 *   id="mitigating control-1"
 *   data={{ label: "Mitigating Control" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const MitigatingControlNode = (props: MitigatingControlNodeProps): JSX.Element => {
  const { criticalControlType, effectiveControlType } = props.data;

  const { className, iconClassName, globalIconClassName } = useMemo(() => {
    let className = defaultClassName;
    let iconClassName = defaultIconClassName;
    let globalIconClassName = defaultGlobalIconClassName;

    if (effectiveControlType === EffectiveControlEnum.NON_EFFECTIVE) {
      className = nonEffectiveClassName;
      iconClassName = nonEffectiveIconClassName;
      globalIconClassName = nonEffectiveGlobalIconClassName;
    } else if (criticalControlType === CriticalControlEnum.CRITICAL) {
      className = criticalClassName;
      iconClassName = criticalIconClassName;
      globalIconClassName = criticalGlobalIconClassName;
    }

    return { className, iconClassName, globalIconClassName };
  }, [criticalControlType, effectiveControlType]);

  const data = useMemo(() => {
    return {
      ...props.data,
      className,
      iconClassName,
      globalIconClassName,
      showRecordIndicators: true,
      showLinkActionIcon: true,
      showUnlinkActionIcon: true,
    };
  }, [props.data, className]);

  return <BaseNode {...props} data={data} />;
};

export default MitigatingControlNode;
