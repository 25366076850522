import { createSelector } from 'reselect';

const selectRaw = (state) => state.diagram;

const selectBowtieData = createSelector([selectRaw], (diagram) => diagram.bowtieData);

const selectLoading = createSelector([selectRaw], (diagram) => diagram.loading);

const selectErrorMessage = createSelector([selectRaw], (diagram) => diagram.errorMessage);

const selectModifyLoading = createSelector([selectRaw], (diagram) => diagram.modifyBowtieData.loading);

const selectFormRecords = createSelector([selectRaw], (diagram) => diagram.formRecords.items);

const selectFormRecordsLoading = createSelector([selectRaw], (diagram) => diagram.formRecords.loading);

const selectFormRecordsErrorMessage = createSelector([selectRaw], (diagram) => diagram.formRecords.errorMessage);

const selectQueues = createSelector([selectRaw], (diagram) => diagram.queue);

const selectDiagramLayout = createSelector([selectRaw], (diagram) => diagram.diagramLayout);

const diagramSelectors = {
  selectBowtieData,
  selectLoading,
  selectErrorMessage,
  selectModifyLoading,
  selectFormRecords,
  selectFormRecordsLoading,
  selectFormRecordsErrorMessage,

  selectQueues,
  selectDiagramLayout,
};

export default diagramSelectors;
