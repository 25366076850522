import { Node, NodeProps } from '@xyflow/react';
import { memo } from 'react';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';

type PreventativeControlsContainerNodeData = BaseContainerNodeData;
export type PreventativeControlsContainerNodeType = Node<PreventativeControlsContainerNodeData>;
type PreventativeControlsContainerNodeProps = NodeProps<PreventativeControlsContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Preventative Controls';
const className = 'bt-border-pc-border bt-bg-pc-bg bt-text-pc-fg';
const data = { label, className };

/**
 * A React functional component that renders a container node for Preventative Controls.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {PreventativeControlsContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <PreventativeControlsContainerNode />
 * ```
 */
const PreventativeControlsContainerNode = (props: PreventativeControlsContainerNodeProps): JSX.Element => {
  return <BaseContainerNode {...props} id={id} data={data} />;
};

export default memo(PreventativeControlsContainerNode);
