import { ModalDialog } from '@myosh/odin-components';
import React from 'react';
import { ControlPayload } from '../../../../modules/diagram/controlActions';
import ActionModalContent from './action-modal.component';

type RectangleUnlinkConfirmationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDelete: (rawElement?: ControlPayload) => void;
  rawElement: ControlPayload | undefined;
};

function RectangleUnlinkConfirmationModal({
  open,
  setOpen,
  onDelete,
  rawElement,
}: RectangleUnlinkConfirmationModalProps) {
  function handleClickUnlink(event: React.MouseEvent) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    setOpen(false);
    onDelete(rawElement);
  }

  function handleCloseModal(event: React.MouseEvent) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    setOpen(false);
  }

  function handleModalHidden() {
    setOpen(false);
  }

  return (
    <ModalDialog header="" visible={open} hidden={handleModalHidden} shouldCheckZIndex={true}>
      <ActionModalContent
        actionButton={{
          onAction: handleClickUnlink,
          text: 'Unlink',
        }}
        onClose={handleCloseModal}
        text="Are you sure you want to remove this control from this Bowtie?"
      />
    </ModalDialog>
  );
}

export default RectangleUnlinkConfirmationModal;
