import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useDiagramContext } from '../../../../context/diagram.context';
import { cn } from '../../../../helpers/util';
import { ControlPayload } from '../../../../modules/diagram/controlActions';
import diagramActions from '../../../../modules/diagram/diagramActions';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import DiagramService from '../../../../modules/diagram/diagramService';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import { BowtieStateCauseData, BowtieStateConsequenceData } from '../../../../services/bowtie-data-types';
import LoadingControls from '../../../common/loading-controls.component';
import { ContainerType } from '../controls.component';
import Rectangle from '../rectangle/rectangle';

const diagramService = new DiagramService();

// SingleElementLine component
interface SingleElementLineProps {
  id: string;
  type: ContainerType;
  index: number;
  line: BowtieStateCauseData | BowtieStateConsequenceData;
}

const SingleElementLine = ({ id, index, line, type }: SingleElementLineProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { isAIGenerated } = useDiagramContext();
  const dispatch = useAppDispatch();
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const [disabled, setDisabled] = useState(false);
  const [editElement, setEditElement] = useState(false);

  const isCause = type === ContainerType.CAUSES;

  const addElement = (payload: { id: number | string; value: string }) => {
    if (isAIGenerated === true) {
      isCause
        ? dispatch(diagramActions.addCauseToAIDiagram(payload))
        : dispatch(diagramActions.addConsequenceToAIDiagram(payload));
    } else {
      isCause ? dispatch(diagramActions.doAddCause(payload)) : dispatch(diagramActions.doAddConsequence(payload));
    }
  };

  const removeElement = (payload: { id: number | string; parentId?: number }) => {
    isCause
      ? dispatch(diagramActions.doRemoveCause(payload.id))
      : dispatch(diagramActions.doRemoveConsequence(payload.id));
  };

  const onAddElement = async () => {
    dispatch(diagramActions.doSetModifyBowtieDataLoading(true));
    const inputRefValue = inputRef?.current?.value;

    if (inputRefValue) {
      setDisabled(true);
      if (inputRefValue === line.value) {
        setEditElement(false);
        setDisabled(false);
      } else if (editElement) {
        const diagramUpdatePayload = { id: line.id!, value: inputRef.current.value };

        if (isAIGenerated === true) {
          isCause
            ? dispatch(diagramActions.updateCauseInDiagram(diagramUpdatePayload))
            : dispatch(diagramActions.updateConsequenceInDiagram(diagramUpdatePayload));
        } else {
          const response = isCause
            ? await diagramService.updateCause(
                bowtieData,
                line as BowtieStateCauseData,
                inputRef.current.value,
                dispatch
              )
            : await diagramService.updateConsequence(
                bowtieData,
                line as BowtieStateConsequenceData,
                inputRef.current.value,
                dispatch
              );

          if (response.result) {
            isCause
              ? dispatch(diagramActions.updateCauseInDiagram(diagramUpdatePayload))
              : dispatch(diagramActions.updateConsequenceInDiagram(diagramUpdatePayload));
          } else {
            toast.error(`Unable to update ${isCause ? 'cause' : 'consequence'}`);
          }
        }

        setEditElement(false);
        setDisabled(false);
      } else {
        setEditElement(false);
        addElement({
          id: line.id!,
          value: inputRefValue,
        });
        setDisabled(true);
      }
    } else if (inputRefValue?.length === 0 && isAIGenerated && editElement) {
      setDisabled(false);
      setEditElement(false);
      removeElement({ id: line.id! });
    } else {
      if (type === ContainerType.CAUSES) {
        dispatch(diagramActions.doRemoveCause(line.id));
      } else {
        dispatch(diagramActions.doRemoveConsequence(line.id));
      }

      setDisabled(false);
      setEditElement(false);
    }
    dispatch(diagramActions.doSetModifyBowtieDataLoading(false));
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !event.shiftKey) {
      event.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleOnRectangularEdit = () => {
    if (userWithPermissions?.permissions?.editRecord?.[id] && !disabled) {
      setEditElement(true);
      setDisabled(false);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.selectionStart = inputRef.current.value.length;
        }
      });
    }
  };

  if (!line.value || editElement) {
    return (
      <div>
        <textarea
          autoFocus
          onKeyDown={handleOnKeyDown}
          key={index}
          defaultValue={line.value ? line.value : ''}
          id={`${id + '_' + (index + 1)}`}
          ref={inputRef}
          disabled={disabled}
          onBlur={onAddElement}
          rows={2}
          className={cn(
            'bt-relative bt-z-[3000] bt-mb-32 bt-flex bt-h-[60px] bt-w-[125px] bt-resize-none bt-items-center bt-justify-center bt-rounded-lg bt-border-2 bt-border-dashed bt-border-primary-4 bt-bg-gray-5 bt-p-[10px] bt-text-center bt-text-xs bt-text-gray-1 bt-outline-none',
            { 'bt-border-solid bt-border-gray-1 bt-border-opacity-80 bt-text-gray-1 bt-text-opacity-80': disabled }
          )}
        />
        {disabled && (
          <div className="bt-relative bt-bottom-[6.6rem] bt-left-[2.4rem]">
            <LoadingControls />
          </div>
        )}
      </div>
    );
  }

  const form = isCause ? bowtieData.bowtieConfiguration.causes.form : bowtieData.bowtieConfiguration.consequences.form;

  return (
    <div className="bt-mb-32" key={`${id}_${index + 1}_wrapper`}>
      <Rectangle
        id={`${id}_${index + 1}`}
        type={type}
        link={line.linkUrl}
        text={line.value}
        form={form}
        status={line.status}
        click={handleOnRectangularEdit}
        // 'rawElement' and 'removeElement are relevant only for editing AI generated diagrams
        rawElement={{ ...line } as unknown as ControlPayload}
        removeElement={removeElement}
      />
    </div>
  );
};

export default SingleElementLine;
