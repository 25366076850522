import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './handles.component';

type HazardNodeData = BaseNodeData;
export type HazardNodeType = Node<HazardNodeData>;
type HazardNodeProps = NodeProps<HazardNodeType>;

const className = 'bt-text-sm bt-bg-hazard-bg bt-text-hazard-fg bt-border-hazard-border';
const iconClassName = 'bt-text-hazard-bg bt-stroke-hazard-fg';
const handles: HandlesProps = { bottomHandleId: 'bottom', bottomHandleType: 'source' };
const staticData = { className, iconClassName, handles };

/**
 * A component that represents a hazard node in a flow diagram.
 * Extends the base node component with hazard-specific functionality.
 *
 * @component
 * @param {HazardNodeProps} props - The properties for the hazard node component
 * @returns {JSX.Element} A rendered hazard node component
 *
 * @example
 * ```tsx
 * <HazardNode
 *   id="hazard"
 *   data={{ label: "Hazard" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const HazardNode = (props: HazardNodeProps): JSX.Element => {
  const data = useMemo(() => {
    return { ...props.data, ...staticData };
  }, [props.data.label, props.data.editMode]);

  return <BaseNode {...props} data={data} />;
};

export default HazardNode;
