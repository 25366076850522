import { cn } from '../../../../helpers/util';
import { ContainerType } from '../controls.component';
import { CriticalControlType, EffectiveControlType } from '../multi-line-container/multi-element-line';

// Resolves the background color class name
export const resolveBackgroundClassName = (
  type: ContainerType,
  criticalControlType?: CriticalControlType,
  effectiveControlType?: EffectiveControlType
) => {
  if (type === ContainerType.PREVENTATIVE_CONTROLS || type === ContainerType.MITIGATING_CONTROLS) {
    if (effectiveControlType === EffectiveControlType.NON_EFFECTIVE) {
      return cn(
        { 'bt-bg-pc-non_critical-non_effective-bg': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-bg-mc-non_critical-non_effective-bg': type === ContainerType.MITIGATING_CONTROLS }
      );
    } else if (criticalControlType === CriticalControlType.CRITICAL) {
      return cn(
        { 'bt-bg-pc-critical-effective-bg': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-bg-mc-critical-effective-bg': type === ContainerType.MITIGATING_CONTROLS }
      );
    }

    // default
    return cn(
      { 'bt-bg-pc-default-bg': type === ContainerType.PREVENTATIVE_CONTROLS },
      { 'bt-bg-mc-default-bg': type === ContainerType.MITIGATING_CONTROLS }
    );
  }

  return cn(
    { 'bt-bg-causes-box-bg': type === ContainerType.CAUSES },
    { 'bt-bg-consequences-box-bg': type === ContainerType.CONSEQUENCES },
    { 'bt-bg-hazard-bg': type === ContainerType.HAZARD },
    { 'bt-bg-mue-bg': type === ContainerType.MUE }
  );
};

// Resolves the border color class name
export const resolveBorderClassName = (
  type: ContainerType,
  criticalControlType?: CriticalControlType,
  effectiveControlType?: EffectiveControlType
) => {
  if (type === ContainerType.PREVENTATIVE_CONTROLS || type === ContainerType.MITIGATING_CONTROLS) {
    if (effectiveControlType === EffectiveControlType.NON_EFFECTIVE) {
      return cn(
        { 'bt-border-pc-non_critical-non_effective-border': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-border-mc-non_critical-non_effective-border': type === ContainerType.MITIGATING_CONTROLS }
      );
    } else if (criticalControlType === CriticalControlType.CRITICAL) {
      return cn(
        { 'bt-border-pc-critical-effective-border': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-border-mc-critical-effective-border': type === ContainerType.MITIGATING_CONTROLS }
      );
    }

    //default
    return cn(
      resolveBackgroundClassName,
      { 'bt-border-pc-default-border': type === ContainerType.PREVENTATIVE_CONTROLS },
      { 'bt-border-mc-default-border': type === ContainerType.MITIGATING_CONTROLS }
    );
  }

  return cn(
    { 'bt-border-causes-box-border': type === ContainerType.CAUSES },
    { 'bt-border-consequences-box-border': type === ContainerType.CONSEQUENCES },
    { 'bt-border-hazard-border': type === ContainerType.HAZARD },
    { 'bt-border-mue-border': type === ContainerType.MUE }
  );
};

// Resolves the text color class name
export const resolveTextClassName = (
  type: ContainerType,
  criticalControlType?: CriticalControlType,
  effectiveControlType?: EffectiveControlType
) => {
  if (type === ContainerType.PREVENTATIVE_CONTROLS || type === ContainerType.MITIGATING_CONTROLS) {
    if (effectiveControlType === EffectiveControlType.NON_EFFECTIVE) {
      return cn(
        { 'bt-text-pc-non_critical-non_effective-fg': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-text-mc-non_critical-non_effective-fg': type === ContainerType.MITIGATING_CONTROLS }
      );
    } else if (criticalControlType === CriticalControlType.CRITICAL) {
      return cn(
        { 'bt-text-pc-critical-effective-fg': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-text-mc-critical-effective-fg': type === ContainerType.MITIGATING_CONTROLS }
      );
    }

    // default
    return cn(
      { 'bt-text-pc-default-fg': type === ContainerType.PREVENTATIVE_CONTROLS },
      { 'bt-text-mc-default-fg': type === ContainerType.MITIGATING_CONTROLS }
    );
  }

  return cn(
    { 'bt-text-causes-box-fg': type === ContainerType.CAUSES },
    { 'bt-text-consequences-box-fg': type === ContainerType.CONSEQUENCES },
    { 'bt-text-hazard-fg': type === ContainerType.HAZARD },
    { 'bt-text-mue-fg': type === ContainerType.MUE }
  );
};

// Resolves the text class name for the icons used with rectangles
export const resolveIconClassName = (
  type: ContainerType,
  criticalControlType?: CriticalControlType,
  effectiveControlType?: EffectiveControlType
) => {
  if (type === ContainerType.PREVENTATIVE_CONTROLS || type === ContainerType.MITIGATING_CONTROLS) {
    if (effectiveControlType === EffectiveControlType.NON_EFFECTIVE) {
      return cn(
        {
          'bt-text-pc-non_critical-non_effective-bg bt-stroke-pc-non_critical-non_effective-fg':
            type === ContainerType.PREVENTATIVE_CONTROLS,
        },
        {
          'bt-text-mc-non_critical-non_effective-bg bt-stroke-mc-non_critical-non_effective-fg':
            type === ContainerType.MITIGATING_CONTROLS,
        }
      );
    } else if (criticalControlType === CriticalControlType.CRITICAL) {
      return cn(
        {
          'bt-text-pc-critical-effective-bg bt-stroke-pc-critical-effective-fg':
            type === ContainerType.PREVENTATIVE_CONTROLS,
        },
        {
          'bt-text-mc-critical-effective-bg bt-stroke-mc-critical-effective-fg':
            type === ContainerType.MITIGATING_CONTROLS,
        }
      );
    }

    // default
    return cn(
      { 'bt-text-pc-default-bg bt-stroke-pc-default-fg': type === ContainerType.PREVENTATIVE_CONTROLS },
      { 'bt-text-mc-default-bg bt-stroke-mc-default-fg': type === ContainerType.MITIGATING_CONTROLS }
    );
  }

  return cn(
    { 'bt-text-causes-box-bg bt-stroke-causes-box-fg': type === ContainerType.CAUSES },
    { 'bt-text-consequences-box-bg bt-stroke-consequences-box-fg': type === ContainerType.CONSEQUENCES },
    { 'bt-text-hazard-bg bt-stroke-hazard-fg': type === ContainerType.HAZARD },
    { 'bt-text-mue-bg bt-stroke-mue-fg': type === ContainerType.MUE }
  );
};

// Resolves the special FA icon foreground class name (fg color is same as border color)
export const resolveIconForegroundClassName = (
  type: ContainerType,
  criticalControlType?: CriticalControlType,
  effectiveControlType?: EffectiveControlType
) => {
  if (type === ContainerType.PREVENTATIVE_CONTROLS || type === ContainerType.MITIGATING_CONTROLS) {
    if (effectiveControlType === EffectiveControlType.NON_EFFECTIVE) {
      return cn(
        { 'bt-text-pc-non_critical-non_effective-border': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-text-mc-non_critical-non_effective-border': type === ContainerType.MITIGATING_CONTROLS }
      );
    } else if (criticalControlType === CriticalControlType.CRITICAL) {
      return cn(
        { 'bt-text-pc-critical-effective-border': type === ContainerType.PREVENTATIVE_CONTROLS },
        { 'bt-text-mc-critical-effective-border': type === ContainerType.MITIGATING_CONTROLS }
      );
    }

    //default
    return cn(
      resolveBackgroundClassName,
      { 'bt-text-pc-default-border': type === ContainerType.PREVENTATIVE_CONTROLS },
      { 'bt-text-mc-default-border': type === ContainerType.MITIGATING_CONTROLS }
    );
  }

  return cn(
    { 'bt-text-causes-box-border': type === ContainerType.CAUSES },
    { 'bt-text-consequences-box-border': type === ContainerType.CONSEQUENCES },
    { 'bt-text-hazard-border ': type === ContainerType.HAZARD },
    { 'bt-text-mue-border': type === ContainerType.MUE }
  );
};
