import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '@myosh/odin-components';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { cn } from '../../../../helpers/util';
import diagramActions from '../../../../modules/diagram/diagramActions';
import diagramSelectors from '../../../../modules/diagram/diagramSelectors';
import DiagramService from '../../../../modules/diagram/diagramService';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import { BowtieBasicRecord, BowtieStateControlData, BowtieStateData } from '../../../../services/bowtie-data-types';

import { useDiagramContext } from '../../../../context/diagram.context';
import { RecordResult } from '../../../../services/record-data-types';
import LoadingControls from '../../../common/loading-controls.component';
import { LINE_DIRECTION } from '../rectangle/rectangle';

const diagramService = new DiagramService();

interface TextAreaProps {
  id: string;
  index: number;
  formId: number;
  defaultValue: string;
  elementId?: number | string;
  parentId: number;
  line: BowtieBasicRecord;
  lineDirection: string;
  addElement: (payload: { id: string; value: string; parentId?: number; existingElement?: RecordResult }) => void;
  removeElement: (payload: { id: string; parentId: number }) => void;
  setElementToEdit: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TextArea = ({
  id,
  index,
  formId,
  defaultValue,
  elementId,
  parentId,
  line,
  lineDirection,
  addElement,
  removeElement,
  setElementToEdit,
}: TextAreaProps) => {
  const [cursorIndex, setCursorIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [showRecordList, setShowRecordList] = useState(true);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const recordListContainerRef = useRef<HTMLDivElement>(null);
  const disableBlurHandler = useRef(false);

  const dispatch = useAppDispatch();
  const bowtieData: BowtieStateData = useAppSelector(diagramSelectors.selectBowtieData);
  const records = useAppSelector(diagramSelectors.selectFormRecords);
  const recordsLoading = useAppSelector(diagramSelectors.selectFormRecordsLoading);
  const { isAIGenerated } = useDiagramContext();

  const isPreventativeControl = id === 'preventative_control';

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = defaultValue;
      inputRef.current.focus();
    }

    return () => {
      dispatch(diagramActions.doResetFormRecords());
    };
  }, []);

  const getControlsFromBowtieData = useCallback(() => {
    let controls: Array<BowtieStateControlData> = [];

    if (bowtieData?.causes) {
      bowtieData.causes.forEach((cause) => {
        controls = [...controls, ...cause.preventativeControls] as Array<BowtieStateControlData>;
      });
    }

    if (bowtieData?.consequences) {
      bowtieData.consequences.forEach((consequence) => {
        controls = [...controls, ...consequence.mitigatingControls] as Array<BowtieStateControlData>;
      });
    }

    return controls.filter((control, index, controlArray) => {
      return controlArray.findIndex((c) => c.id === control.id) === index && typeof control.id === 'number';
    });
  }, [bowtieData]);

  const filterRelevantControls = (records: Array<RecordResult>) => {
    const diagramControls = getControlsFromBowtieData();

    return records
      ?.filter((record) => {
        const globalField = record.fields['Global'];

        return globalField || diagramControls.find((diagramControl) => diagramControl.id === record.id);
      })
      .map((record: RecordResult) => {
        const globalField = record.fields['Global'];

        return {
          ...record,
          global: Boolean(globalField),
        };
      })
      .sort((recordA: RecordResult, recordB: RecordResult) => {
        if (recordA.displayText && recordB.displayText) {
          return recordA.displayText.localeCompare(recordB.displayText);
        }
        return 0;
      });
  };

  const onAddControl = async () => {
    dispatch(diagramActions.doSetModifyBowtieDataLoading(true));

    if (inputRef.current?.value && inputRef.current.value.length > 0) {
      const hasDefaultValue = Boolean(defaultValue); // indicates if this is an existing item
      if (elementId && typeof elementId !== 'number' && !hasDefaultValue) {
        setShowRecordList(false);
        addElement({
          id: elementId,
          value: inputRef.current.value,
          parentId,
        });
        setDisabled(true);
      } else {
        if (inputRef.current.value !== defaultValue) {
          setDisabled(true);

          const diagramUpdatePayload = {
            id: elementId,
            value: inputRef.current.value,
            parentId,
          };

          if (isAIGenerated === true) {
            isPreventativeControl
              ? dispatch(diagramActions.updatePreventativeControlInDiagram(diagramUpdatePayload))
              : dispatch(diagramActions.updateMitigatingControlsInDiagram(diagramUpdatePayload));
          } else {
            const response = isPreventativeControl
              ? await diagramService.updatePreventativeControl(bowtieData, line, inputRef.current?.value, dispatch)
              : await diagramService.updateMitigatingControl(bowtieData, line, inputRef.current?.value, dispatch);

            if (response.result) {
              isPreventativeControl
                ? dispatch(diagramActions.updatePreventativeControlInDiagram(diagramUpdatePayload))
                : dispatch(diagramActions.updateMitigatingControlsInDiagram(diagramUpdatePayload));
            } else {
              toast.error(`Unable to update ${isPreventativeControl ? 'preventative control' : 'mitigating control'}`);
            }
          }

          setElementToEdit(undefined);
        } else {
          setDisabled(false);
          setElementToEdit(undefined);
        }
      }
    } else {
      if (elementId && typeof elementId !== 'number') {
        removeElement({ id: elementId, parentId });
      }
      setDisabled(false);
      setElementToEdit(undefined);
    }

    dispatch(diagramActions.doSetModifyBowtieDataLoading(false));
  };

  const onAddExistingControl = (record: RecordResult) => {
    dispatch(diagramActions.doSetModifyBowtieDataLoading(true));

    if (inputRef.current) {
      inputRef.current.value = record.fields['Control Name'] as string;
    }

    setShowRecordList(false);

    const recordControlName = record.fields['Control Name'] as string;

    addElement({
      id: String(elementId),
      value: recordControlName,
      parentId,
      existingElement: record,
    });

    setDisabled(true);

    dispatch(diagramActions.doSetModifyBowtieDataLoading(false));
  };

  const handleKeyDownEvent = (event: React.KeyboardEvent) => {
    event.stopPropagation();

    if (event.code === 'ArrowDown') {
      const downIndex = cursorIndex + 1 > filterRelevantControls(records).length ? cursorIndex : cursorIndex + 1;
      const recordItem = document.getElementById(`record-item-${downIndex - 1}`);
      recordItem && recordItem.scrollIntoView({ block: 'nearest', inline: 'nearest' });

      setCursorIndex(downIndex);
    } else if (event.code === 'ArrowUp') {
      const upIndex = !cursorIndex ? 0 : cursorIndex - 1;
      const recordItem = document.getElementById(`record-item-${upIndex - 1}`);
      recordItem && recordItem.scrollIntoView({ block: 'nearest', inline: 'nearest' });

      setCursorIndex(upIndex);
    } else if ((event.code === 'Enter' || event.code === 'NumpadEnter') && cursorIndex > 0 && !event.shiftKey) {
      onAddExistingControl(filterRelevantControls(records)[cursorIndex - 1]);
    }
  };

  const handleTextAreaKeyDownEvent = (event: React.KeyboardEvent) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && !cursorIndex && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();

      // In Firefox, the blur handler is ran immediattely after the keydown event,
      // which causes the control to be added twice.
      // This is a workaround to avoid that issue (MYOSH-7501)
      disableBlurHandler.current = true;

      onAddControl();
    }
  };

  const handleOnChangeEvent =
    isAIGenerated === false
      ? debounce((event: React.ChangeEvent<HTMLTextAreaElement>) => {
          event.stopPropagation();
          event.preventDefault();
          if (event.target.value?.length >= 3) {
            dispatch(
              diagramActions.doFetchRecordsByFormId(formId, {
                filter: `Control Name:like:${event.target.value}`,
              })
            );
          } else {
            dispatch(diagramActions.doResetFormRecords());
          }
        }, 800)
      : undefined;

  const handleTextAreaBlur = () => {
    if (disableBlurHandler.current) {
      // Workaround to fix issue in Firefox (MYOSH-7501)
      disableBlurHandler.current = false;
      return;
    }

    onAddControl();
  };

  const handleRecordListItemClick = (record: RecordResult) => {
    // In Firefox, the blur handler is ran immediattely after the click,
    // which causes the control to be added twice.
    // This is a workaround to avoid that issue (MYOSH-7501)
    disableBlurHandler.current = true;

    onAddExistingControl(record);
  };

  return (
    <div
      id={id}
      key={`${id}_${index}`}
      className="bt-relative bt-flex bt-h-[60px] bt-justify-center"
      onKeyDown={handleKeyDownEvent}
    >
      <div>
        <textarea
          id={`${id + '_input'}`}
          ref={inputRef}
          disabled={disabled}
          onBlur={handleTextAreaBlur}
          onChange={handleOnChangeEvent}
          onKeyDown={handleTextAreaKeyDownEvent}
          rows={3}
          cols={10}
          className={cn(
            'bt-relative bt-z-[3000] bt-flex bt-h-[60px] bt-resize-none bt-items-center bt-justify-center bt-rounded-lg bt-border-2 bt-border-dashed bt-border-primary-4 bt-bg-gray-5 bt-p-[10px] bt-text-center bt-text-xs bt-text-gray-1 bt-outline-none',
            { 'bt-w-[125px]': defaultValue },
            { 'bt-w-[185px]': !defaultValue },
            { 'bt-border-solid bt-border-gray-1 bt-border-opacity-80 bt-text-gray-1 bt-text-opacity-80': disabled }
          )}
        />
        {disabled && (
          <div className={cn('bt-absolute bt-right-2 bt-top-16 bt-z-[9999]', { 'bt-right-12': defaultValue })}>
            <LoadingControls />
          </div>
        )}
      </div>
      <div
        className={cn(
          'bt-absolute bt-w-[1px] bt-bg-cline',
          {
            'bt-top-[90%] bt-h-[23px]': lineDirection === LINE_DIRECTION.BOTTOM,
          },
          { 'bt-bottom-[90%] bt-h-[27px]': lineDirection === LINE_DIRECTION.TOP }
        )}
      />

      {showRecordList && !defaultValue && (
        <div
          className="bt-shadow-[20px_10px_15px_0.3_rgba(0,0,0, 0.05)] bt-absolute -bt-left-[22%] bt-top-full bt-z-[9000] bt-max-h-[150px] bt-w-[150%] bt-overflow-auto bt-rounded-sm bt-bg-gray-5 bt-px-0 bt-py-2"
          ref={recordListContainerRef}
        >
          {recordsLoading ? (
            <div className="bt-text-primary">
              <Loader size="sm" />
            </div>
          ) : filterRelevantControls(records)?.length > 0 ? (
            filterRelevantControls(records)?.map((record: RecordResult, index: number) => {
              const handleClick = () => handleRecordListItemClick(record);

              return (
                <div
                  key={`record-item-${index}`}
                  className={cn(
                    'bt-flex bt-items-center bt-justify-between bt-break-words bt-bg-gray-5 bt-px-4 bt-py-[0.3rem] bt-text-left bt-text-xs hover:bt-cursor-pointer hover:bt-bg-gray-4',
                    { 'bt-bg-gray-4': cursorIndex - 1 === index }
                  )}
                  onClick={handleClick}
                  id={`record-item-${index}`}
                >
                  <p>{record.fields['Control Name'] as string}</p>
                  {Boolean(record.fields['Global']) && <FontAwesomeIcon icon={faGlobe} className="bt-text-primary" />}
                </div>
              );
            })
          ) : (
            '... press enter to create ...'
          )}
        </div>
      )}
    </div>
  );
};

export default TextArea;
