import { DiagramConfiguration } from '../@types/diagram';
import { CriticalControlEnum, EffectiveControlEnum } from './node-util';

const causesCount = 2;
const consequencesCount = 3;
const preventativeControlsCount = 3;
const mitigatingControlsCount = 2;

// Example of a default diagram configuration
export const initialDiagramConfig: DiagramConfiguration = {
  causes: Array.from({ length: causesCount }, (_, causeIndex) => ({
    id: crypto.randomUUID(),
    label: `Cause ${causeIndex + 1}`,
    controls: Array.from({ length: preventativeControlsCount }, (_, controlIndex) => ({
      id: crypto.randomUUID(),
      label: `Preventative Control ${causeIndex + 1}.${controlIndex + 1}`,
      effectiveControlType:
        controlIndex % 2 === 0 ? EffectiveControlEnum.EFFECTIVE : EffectiveControlEnum.NON_EFFECTIVE,
      criticalControlType: controlIndex % 3 === 0 ? CriticalControlEnum.CRITICAL : CriticalControlEnum.NON_CRITICAL,
      global: controlIndex % 4 === 0,
      draft: controlIndex % 5 === 0,
    })),
  })),
  consequences: Array.from({ length: consequencesCount }, (_, consequenceIndex) => ({
    id: crypto.randomUUID(),
    label: `Consequence ${consequenceIndex + 1}`,
    controls: Array.from({ length: mitigatingControlsCount }, (_, controlIndex) => ({
      id: crypto.randomUUID(),
      label: `Mitigating Control ${consequenceIndex + 1}.${controlIndex + 1}`,
      effectiveControlType:
        controlIndex % 2 === 0 ? EffectiveControlEnum.EFFECTIVE : EffectiveControlEnum.NON_EFFECTIVE,
      criticalControlType: controlIndex % 3 === 0 ? CriticalControlEnum.CRITICAL : CriticalControlEnum.NON_CRITICAL,
      global: controlIndex % 4 === 0,
      draft: controlIndex % 5 === 0,
    })),
  })),
  hazard: { id: crypto.randomUUID(), label: 'Hazard' },
  mue: { id: crypto.randomUUID(), label: 'Unwanted Event' },
};
