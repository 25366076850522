import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { DynamicFormSettings } from '../../../../@types/form-data-types';
import { useDiagramContext } from '../../../../context/diagram.context';
import { cn } from '../../../../helpers/util';
import controlActions, { ControlPayload } from '../../../../modules/diagram/controlActions';
import { useAppDispatch } from '../../../../modules/hooks';
import { ContainerType } from '../controls.component';
import { CriticalControlType, EffectiveControlType } from '../multi-line-container/multi-element-line';
import LinkIcon from './icons/link-icon.component';
import UnlinkIcon from './icons/unlink-icon.component';
import RectangleUnlinkConfirmationModal from './rectangle-unlink-confirmation.modal';
import {
  resolveBackgroundClassName,
  resolveBorderClassName,
  resolveIconForegroundClassName,
  resolveTextClassName,
} from './styles-util';

export const LINE_DIRECTION = {
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};

interface RectangleProps {
  id: string;
  type: ContainerType;
  text: string;
  status?: string;
  hasCircleRight?: boolean;
  hasCircleLeft?: boolean;
  hasLine?: boolean;
  lineDirection?: string;
  editable?: boolean;
  parentId?: string;
  click: () => void;
  link?: string;
  form?: DynamicFormSettings;
  rawElement?: ControlPayload;
  criticalControlType?: CriticalControlType;
  effectiveControlType?: EffectiveControlType;
  // only used when editing AI generated diagrams
  removeElement?: (payload: { id: string; parentId: number }) => void;
}

export default function Rectangle({
  id,
  type,
  text,
  hasCircleRight,
  hasCircleLeft,
  hasLine,
  lineDirection,
  link,
  status,
  click,
  form,
  rawElement,
  criticalControlType,
  effectiveControlType,
  removeElement,
}: RectangleProps) {
  const formWorkflowSteps = form && form.workflowSteps;
  const draftWorkflowStep = formWorkflowSteps && formWorkflowSteps.find((workflowStep) => workflowStep.draft);
  const draftWorkflowStepLabel = draftWorkflowStep && draftWorkflowStep?.label;

  const externalLinkRef = useRef<HTMLDivElement>(null);
  const deleteLinkRef = useRef<HTMLDivElement>(null);
  const { isAIGenerated } = useDiagramContext();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const onUnlinkClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (isAIGenerated === true && rawElement && removeElement) {
      // when it's an AI diagram, we can just remove the control without confirmation
      removeElement({ id: String(rawElement.id), parentId: rawElement.parentId });
    } else {
      setOpen(true);
    }
  };

  const onDelete = (rawElement?: ControlPayload) => {
    rawElement && dispatch(controlActions.unlinkControl(rawElement));
  };

  const handleClickRectangle = (event: React.MouseEvent) => {
    if (
      click &&
      (!externalLinkRef.current ||
        (externalLinkRef.current && !externalLinkRef.current.contains(event.currentTarget))) &&
      (!deleteLinkRef.current || (deleteLinkRef.current && !deleteLinkRef.current.contains(event.currentTarget)))
    ) {
      click();
    }
  };

  // Common resolved styles
  const backgroundClassName = resolveBackgroundClassName(type, criticalControlType, effectiveControlType);
  const borderClassName = resolveBorderClassName(type, criticalControlType, effectiveControlType);
  const foregroundClassName = resolveTextClassName(type, criticalControlType, effectiveControlType);
  const iconForegroundClassName = resolveIconForegroundClassName(type, criticalControlType, effectiveControlType);

  const rectangleStyles = cn(
    'bt-group/rectangle bt-relative bt-z-[3000] bt-flex bt-cursor-pointer bt-items-center bt-justify-center bt-rounded-lg bt-border bt-text-center bt-font-normal',
    {
      'bt-h-[60px] bt-w-[125px] bt-text-sm': type === ContainerType.CAUSES,
    },
    {
      'bt-h-[60px] bt-w-[125px] bt-text-sm': type === ContainerType.CONSEQUENCES,
    },
    {
      'bt-h-[100px] bt-w-[155px] bt-text-xl': type === ContainerType.HAZARD,
    },
    {
      'bt-h-[100px] bt-w-[205px] bt-text-xl': type === ContainerType.MUE,
    },
    {
      'bt-h-[60px] bt-w-[125px] bt-text-sm': type === ContainerType.PREVENTATIVE_CONTROLS,
    },
    {
      'bt-h-[60px] bt-w-[125px] bt-text-sm': type === ContainerType.MITIGATING_CONTROLS,
    },
    backgroundClassName,
    borderClassName,
    foregroundClassName
  );

  return (
    <div id={id} onClick={handleClickRectangle} className={rectangleStyles}>
      <div className="bt-relative bt-flex bt-h-full bt-w-full bt-items-center bt-justify-center" title={text}>
        <div className="bt-absolute bt-z-0 bt-flex bt-h-full bt-w-full bt-justify-center bt-rounded-lg">
          <div className="bt-z-[100] bt-flex bt-flex-col bt-items-center bt-justify-center">
            <p className="bt-m-0 bt-line-clamp-[3] bt-overflow-hidden bt-text-ellipsis bt-whitespace-break-spaces bt-leading-[1.2]">
              {text}
            </p>
          </div>
        </div>
        <div className="bt-absolute bt-flex bt-h-full bt-w-[115%] bt-items-center bt-justify-between">
          {hasCircleRight && (
            <div
              className={cn(
                '-bt-z-[10] -bt-mr-[10px] bt-inline-block bt-h-[20px] bt-w-[20px] bt-rounded-[100%] bt-border bt-bg-mono-1 bt-p-0 bt-leading-5',
                borderClassName
              )}
            />
          )}
          {hasCircleLeft && (
            <div
              className={cn(
                '-bt-z-[10] -bt-ml-[10px] bt-inline-block bt-h-[20px] bt-w-[20px] bt-rounded-[100%] bt-border bt-bg-mono-1 bt-p-0 bt-leading-5',
                borderClassName
              )}
            />
          )}
        </div>
      </div>

      {hasLine && (
        <div
          className={cn(
            'bt-absolute bt-w-[1px] bt-bg-cline',
            { 'bt-top-[102%] bt-h-[17px]': lineDirection === LINE_DIRECTION.BOTTOM },
            { 'bt-bottom-[102%] bt-h-[21px]': lineDirection === LINE_DIRECTION.TOP }
          )}
        />
      )}

      {rawElement?.global && (
        <div className="bt-absolute bt-bottom-0 bt-left-0 bt-z-[6000] -bt-translate-x-1/2 bt-translate-y-1/2">
          <FontAwesomeIcon icon={faGlobe} className={iconForegroundClassName} title="Global control" />
        </div>
      )}

      {!rawElement?.global &&
        ((status && status === 'Draft') || (draftWorkflowStepLabel && draftWorkflowStepLabel === status)) && (
          <div
            className={cn(
              'bt-absolute -bt-bottom-[15%] -bt-left-[6%] bt-z-[5000] bt-h-[12px] bt-w-[12px] bt-rounded-[100%] bt-border-[3px]',
              backgroundClassName,
              borderClassName
            )}
            title="Draft"
          />
        )}

      {(isAIGenerated || (type !== ContainerType.CAUSES && type !== ContainerType.CONSEQUENCES)) &&
        type !== ContainerType.HAZARD &&
        type !== ContainerType.MUE && (
          <div
            ref={deleteLinkRef}
            className="bt-invisible bt-absolute -bt-bottom-[10px] -bt-right-[10px] bt-z-[3000] group-hover/rectangle:bt-visible"
            onClick={onUnlinkClick}
            title="Unlink"
          >
            <UnlinkIcon
              type={type}
              criticalControlType={criticalControlType}
              effectiveControlType={effectiveControlType}
            />
          </div>
        )}

      {link && (
        <div
          className="bt-invisible bt-absolute -bt-right-[10px] -bt-top-[10px] bt-z-[3000] group-hover/rectangle:bt-visible"
          onClick={() => window.open(link)}
          ref={externalLinkRef}
          title="Open in Viking"
        >
          <LinkIcon type={type} criticalControlType={criticalControlType} effectiveControlType={effectiveControlType} />
        </div>
      )}

      <RectangleUnlinkConfirmationModal open={open} setOpen={setOpen} onDelete={onDelete} rawElement={rawElement} />
    </div>
  );
}
