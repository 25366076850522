import { LoginCallback, LogoutCallback } from '@myosh/myosh-login';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigurationProvider } from '../../context/configuration.context';
import RestrictedDiagramRoute from '../../flow/restricted-diagram-route';
import MainFlow from '../../flow/views/main';
import Main from '../../views/main';
import LoginPage from './login-page';
import NoMatchRoute from './no-match-route';
import PrivateRoute from './private-route';

function RoutesComponent() {
  return (
    <>
      <Routes>
        <Route path="/auth/cb/logout" element={<LogoutCallback />} />
        <Route path="/auth/cb/silent" element={<div>Silent renewal callback</div>} />
        <Route path="/auth/cb" element={<LoginCallback />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            index
            element={
              <ConfigurationProvider>
                <Main />
              </ConfigurationProvider>
            }
          />
          <Route path="flow" element={<RestrictedDiagramRoute />}>
            <Route
              index
              element={
                <ConfigurationProvider>
                  <MainFlow />
                </ConfigurationProvider>
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<NoMatchRoute />} />
      </Routes>
    </>
  );
}

export default React.memo(RoutesComponent);
