import diagramActions from '../../../../modules/diagram/diagramActions';
import { useAppDispatch, useAppSelector } from '../../../../modules/hooks';
import userSelectors from '../../../../modules/user/userSelectors';
import Controls, { ContainerType } from '../controls.component';
import LoadingPreview from '../loading-preview';

interface ConsequencesProps {
  isLoading: boolean;
}

const Consequences = ({ isLoading }: ConsequencesProps) => {
  const dispatch = useAppDispatch();
  const userWithPermissions = useAppSelector(userSelectors.selectUser);

  const canCreateConsequences = userWithPermissions?.permissions?.createRecord?.consequences;

  const addConsequenceInput = () => {
    dispatch(diagramActions.doAddConsequenceInput());
  };

  return (
    <Controls
      id="consequences-container"
      type={ContainerType.CONSEQUENCES}
      onAdd={isLoading || !canCreateConsequences ? undefined : addConsequenceInput}
    >
      {isLoading ? (
        <LoadingPreview message="Consequences will be displayed here" />
      ) : canCreateConsequences ? (
        'Add consequences in this box'
      ) : (
        'Consequences will be displayed here'
      )}
    </Controls>
  );
};

export default Consequences;
