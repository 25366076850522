import { Node, NodeProps } from '@xyflow/react';
import { memo } from 'react';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';

type MitigatingControlsContainerNodeData = BaseContainerNodeData;
export type MitigatingControlsContainerNodeType = Node<MitigatingControlsContainerNodeData>;
type MitigatingControlsContainerNodeProps = NodeProps<MitigatingControlsContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Mitigating Controls';
const className = 'bt-border-mc-border bt-bg-mc-bg bt-text-mc-fg';
const data = { label, className };

/**
 * A React functional component that renders a container node for Mitigating Controls.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {MitigatingControlsContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <MitigatingControlsContainerNode />
 * ```
 */
const MitigatingControlsContainerNode = (props: MitigatingControlsContainerNodeProps): JSX.Element => {
  return <BaseContainerNode {...props} id={id} data={data} />;
};

export default memo(MitigatingControlsContainerNode);
