import { XYPosition } from '@xyflow/react';
import BaseEdge, { BaseEdgeProps } from '../components/edge/base-edge.component';

export const diagramEdgeTypes = {
  'base-edge': BaseEdge,
};

/**
 * Generates the connector path for an edge.
 *
 * @param {number} handleX - The X coordinate of the handle point.
 * @param {number} handleY - The Y coordinate of the handle point.
 * @param {number} targetY - The Y coordinate of the target point.
 *
 * @returns {string} The SVG path string for the connector path.
 */
export const getPathLineFromNodeHandleToMainPath = (handleX: number, handleY: number, targetY: number) => {
  return `M ${handleX} ${handleY} L ${handleX} ${targetY}`;
};

/**
 * Resolves the main path values for a given edge in a diagram.
 *
 * @param props - The properties of the base edge.
 * @param containerPosition - The position of the container.
 * @param containerDimensions - The dimensions of the container.
 *
 * @returns An object containing the main X and Y coordinates, and the main path parts.
 * @returns mainX - The main X coordinate.
 * @returns mainY - The main Y coordinate.
 * @returns mainPathPart1 - The first part of the main path.
 * @returns mainPathPart2 - The second part of the main path.
 */
export const resolveMainPathValues = (
  props: BaseEdgeProps,
  containerPosition: XYPosition,
  containerDimensions: {
    width?: number;
    height?: number;
  }
) => {
  const { sourceX, sourceY, targetX, targetY, data } = props;
  const { direction, diagramType } = data ?? {};

  let mainX, mainPathPart2;

  const mainY = sourceY + containerPosition.y;

  if (diagramType === 'butterfly') {
    mainX =
      direction === 'left-to-right' ? containerPosition.x : containerPosition.x + (containerDimensions.width ?? 0);

    mainPathPart2 = getMainPathPart2(targetX, targetY, sourceX, sourceY);
  } else {
    // bowtie diagram
    mainX =
      direction === 'left-to-right' ? containerPosition.x + (containerDimensions.width ?? 0) : containerPosition.x;

    mainPathPart2 = getMainPathPart2(targetX, targetY, mainX, mainY);
  }

  const mainPathPart1 = getMainPathPart1(sourceX, sourceY, mainX, mainY);

  return { mainX, mainY, mainPathPart1, mainPathPart2 };
};

const getMainPathPart1 = (sourceX: number, sourceY: number, mainX: number, mainY: number): string => {
  return `M ${sourceX} ${sourceY} L ${mainX} ${mainY}`;
};

const getMainPathPart2 = (targetX: number, targetY: number, mainX: number, mainY: number): string => {
  return `M ${mainX} ${mainY} L ${targetX} ${targetY}`;
};
