import { Button, ModalDialogRef, OdinIcon, OdinIconSize, OdinIconType, SplitButton } from '@myosh/odin-components';
import { useCallback, useRef } from 'react';
import MyoshBowtieLogo from '../../assets/svg/myosh-bowtie-logo.svg';
import { useDiagramContext } from '../../context/diagram.context';
import { useAIAnalysis } from '../../hooks/use-ai-analysis';
import { useAppSelector } from '../../modules/hooks';
import userSelectors from '../../modules/user/userSelectors';
import AiAnalysisConfirmationModal from './ai-analysis-confirmation.modal';
import BowtieWizard, { BowtieWizardRef } from './bowtie-wizard';
import ControlLegend from './control-legend.component';
import CreateRecordsButton from './create-records-button.component';
import ImportBowtieDiagram from './import-bowtie-diagram';
import RecordDropDown from './record-drop-down.component';
import ReloadButton from './reload-button';
import Settings from './settings.component';

type HeaderProps = {
  onAIAnalysisReady: (text: string) => void;
};

function Header({ onAIAnalysisReady }: HeaderProps) {
  const wizardRef = useRef<BowtieWizardRef>(null);
  const importBowtieDiagramRef = useRef<HTMLInputElement>(null);
  const aiAnalysisConfirmationModalRef = useRef<ModalDialogRef>(null);

  const userWithPermissions = useAppSelector(userSelectors.selectUser);
  const canCreateBowtie = userWithPermissions?.permissions?.createRecord?.main;
  const hideAiAnalysisConfirmation = localStorage.getItem('hideAiAnalysisConfirmation');

  const { isAIGenerated, isNewDiagram } = useDiagramContext();
  const { runAIAnalysis, isLoading: aiAnalysisRunning } = useAIAnalysis(onAIAnalysisReady);

  const handleAddBowtie = useCallback(() => {
    window.open(window.location.origin);
  }, []);

  const handleBowtieWizardClick = useCallback(() => {
    return wizardRef.current?.open();
  }, []);

  const handleImportBowtie = useCallback(() => {
    importBowtieDiagramRef.current?.click();
  }, []);

  const handleRunAiAnalysis = () => {
    if (!Boolean(hideAiAnalysisConfirmation)) {
      aiAnalysisConfirmationModalRef.current?.show();
      return;
    }

    runAIAnalysis();
  };

  const handleSubmitConfirmation = (isAiAnalysisConfirmationCheckboxChecked: boolean) => {
    if (isAiAnalysisConfirmationCheckboxChecked) {
      localStorage.setItem('hideAiAnalysisConfirmation', 'true');
    }

    runAIAnalysis();
    aiAnalysisConfirmationModalRef.current?.close();
  };

  const splitButtonItems = [
    {
      label: 'Bowtie AI Wizard',
      command: handleBowtieWizardClick,
      icon: <OdinIcon icon="Magic" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
    {
      label: 'Import Bowtie',
      command: handleImportBowtie,
      icon: <OdinIcon icon="Upload" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
    {
      label: 'AI Analysis',
      command: handleRunAiAnalysis,
      visible: !isNewDiagram,
      disabled: aiAnalysisRunning,
      icon: <OdinIcon icon="Terminal" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />,
    },
  ];

  return (
    <>
      <div
        className="bt-r-16 bt-sticky bt-left-0 bt-top-0 bt-z-[1300] bt-mt-2 bt-flex bt-h-12 bt-justify-between bt-bg-mono-1 bt-px-16"
        id="header"
      >
        <div className="bt-flex bt-flex-row bt-gap-3">
          <img src={MyoshBowtieLogo} alt="myosh-bowtie-logo" />
          {!isAIGenerated && (
            <>
              <RecordDropDown />
              {canCreateBowtie && (
                <SplitButton
                  label="Add Bowtie"
                  onClick={handleAddBowtie}
                  model={splitButtonItems}
                  variant="alternative"
                  className="bt-min-w-52"
                  icon={
                    <OdinIcon icon="Add" type={OdinIconType.Line} size={OdinIconSize.ExtraSmall} className="bt-mr-2" />
                  }
                />
              )}
            </>
          )}
          {isAIGenerated && canCreateBowtie && (
            <div className="bt-flex bt-flex-row bt-gap-2">
              <CreateRecordsButton />
              {/* NOTE: This button does nothing for now */}
              <Button type="text" variant="alternative">
                Save
              </Button>
            </div>
          )}
          {!isAIGenerated && !isNewDiagram && <ReloadButton />}
        </div>
        <div className="bt-flex">
          <ControlLegend />
          <Settings />
        </div>
      </div>
      <BowtieWizard ref={wizardRef} />
      <ImportBowtieDiagram ref={importBowtieDiagramRef} />
      <AiAnalysisConfirmationModal
        ref={aiAnalysisConfirmationModalRef}
        handleSubmitConfirmation={handleSubmitConfirmation}
        handleCloseModal={() => aiAnalysisConfirmationModalRef.current?.close()}
      />
    </>
  );
}

export default Header;
