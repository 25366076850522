import { memo } from 'react';
import { cn } from '../../../helpers/util';

interface AddCauseConsequenceButtonProps {
  label: string;
  className: string;
  hasControls: boolean;
  onClick: () => void;
}

/**
 * A button component for adding causes and consequences in a flow diagram.
 *
 * @component
 * @param {string} label - The text label to display on the button
 * @param {string} className - The CSS class name for custom styling
 * @param {boolean} hasControls - Flag indicating if the button has controls
 * @param {() => void} onClick - Click event handler function
 *
 * @returns {JSX.Element} A button element with conditional styling based on controls
 *
 * @example
 * ```tsx
 * <AddCauseConsequenceButton
 *   label="Add Cause"
 *   className="custom-class"
 *   hasControls={true}
 *   onClick={() => handleClick()}
 * />
 * ```
 */
const AddCauseConsequenceButton = ({
  label,
  className,
  hasControls,
  onClick,
}: AddCauseConsequenceButtonProps): JSX.Element => {
  return (
    <button
      className={cn(
        'nopan nodrag bt-whitespace-nowrap',
        { [className]: hasControls },
        {
          'bt-absolute bt-bottom-0 bt-left-0 bt-right-0 bt-top-0 bt-m-auto bt-h-[50px] bt-w-[100px] bt-text-xs':
            !hasControls,
        }
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default memo(AddCauseConsequenceButton);
