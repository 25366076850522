interface QueryValidation {
  paramValid: boolean;
  missingParams: Array<string>;
  invalidParams: Array<string>;
  initialUrl?: string;
}

import ErrorIcon from '../../assets/images/error.png';

interface GeneralErrorContainerProps {
  errorMessage: string;
  errorDescription: string;
  loading?: boolean;
  validation?: QueryValidation;
}

const container = 'bt-flex bt-h-screen bt-flex-col bt-justify-center bt-items-center bt-p-4';
const infoHeader = 'bt-m-0 bt-text-center bt-text-5xl bt-font-normal bt-tracking-normal  max-sm:bt-text-4xl';
const infoText =
  'bt-mt-4 bt-w-1/2 bt-text-center bt-text-base bt-font-normal bt-text-gray-1 max-lg:bt-w-[80%] max-sm:bt-w-[100%]';
const icon = 'bt-w-[20%] max-sm:bt-w-[40%]';

export default function GeneralErrorContainer({ errorMessage, errorDescription, loading }: GeneralErrorContainerProps) {
  if (loading) {
    return null;
  }

  return (
    <div className={container}>
      <img src={ErrorIcon} alt="missing_info" className={icon} />
      {errorMessage && <h3 className={infoHeader}>{errorMessage}</h3>}
      {errorDescription && <p className={infoText}>{errorDescription}</p>}
    </div>
  );
}
