import { Node, NodeProps } from '@xyflow/react';
import { memo, useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useDiagramContext } from '../../context/diagram.context';
import { DiagramContextState } from '../../context/diagram.store';
import AddCauseConsequenceButton from './add-cause-consequence-button.component';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';

type ConsequecesContainerNodeData = BaseContainerNodeData;
export type ConsequencesContainerNodeType = Node<ConsequecesContainerNodeData>;
type ConsequencesContainerNodeProps = NodeProps<ConsequencesContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Consequences';
const className = 'bt-border-consequences-border bt-bg-consequences-bg bt-text-consequences-fg';
const data = { label, className };

const addButtonLabel = '+ Add Consequece';
const addButtonClassName =
  'bt-text-consequences-fg bt-mt-2 bt-flex bt-w-full bt-justify-center bt-border-2 bt-border-consequences-border bt-bg-consequences-bg bt-px-0 bt-py-2 bt-text-sm bt-text-consequences-border hover:bt-bg-consequences-border hover:bt-text-consequences-box-fg';

const selector = (state: DiagramContextState) => ({
  addNode: state.addNode,
  hasConsequences: state.consequences.length > 0,
});

/**
 * A React functional component that renders a container node for Consequeces.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {ConsequencesContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <ConsequencesContainerNode />
 * ```
 */
const ConsequencesContainerNode = (props: ConsequencesContainerNodeProps): JSX.Element => {
  const { addNode, hasConsequences } = useDiagramContext(useShallow(selector));

  const handleAdd = useCallback(() => {
    addNode('consequence-node', { id: crypto.randomUUID(), label: 'New' });
  }, []);

  return (
    <>
      <BaseContainerNode {...props} id={id} data={data} />
      <AddCauseConsequenceButton
        label={addButtonLabel}
        className={addButtonClassName}
        onClick={handleAdd}
        hasControls={hasConsequences}
      />
    </>
  );
};

export default memo(ConsequencesContainerNode);
