const prefix = 'DIAGRAM';

export const DIAGRAM_SET_LOADING = `${prefix}_SET_LOADING`;

export const DIAGRAM_FETCH_RECORD_START = `${prefix}_FETCH_RECORD_START`;
export const DIAGRAM_FETCH_RECORD_FAIL = `${prefix}_FETCH_RECORD_FAIL`;
export const DIAGRAM_FETCH_RECORD_SUCCESS = `${prefix}_FETCH_RECORD_SUCCESS`;

export const DIAGRAM_ADD_CAUSE_INPUT = `${prefix}_ADD_CAUSE_INPUT`;
export const DIAGRAM_ADD_CAUSE_SUCCESS = `${prefix}_ADD_CAUSE_SUCCESS`;
export const DIAGRAM_REMOVE_CAUSE = `${prefix}_REMOVE_CAUSE`;
export const DIAGRAM_UPDATE_CAUSE = `${prefix}_UPDATE_CAUSE`;

export const DIAGRAM_ADD_CONSEQUENCE_INPUT = `${prefix}_ADD_CONSEQUENCE_INPUT`;
export const DIAGRAM_ADD_CONSEQUENCE_SUCCESS = `${prefix}_ADD_CONSEQUENCE_SUCCESS`;
export const DIAGRAM_REMOVE_CONSEQUENCE = `${prefix}_REMOVE_CONSEQUENCE`;
export const DIAGRAM_UPDATE_CONSEQUENCE = `${prefix}_UPDATE_CONSEQUENCE`;

export const DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT = `${prefix}_ADD_PREVENTATIVE_CONTROLS_INPUT`;
export const DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS = `${prefix}_ADD_PREVENTATIVE_CONTROLS_SUCCESS`;
export const DIAGRAM_REMOVE_PREVENTATIVE_CONTROL = `${prefix}_REMOVE_PREVENTATIVE_CONTROL`;
export const DIAGRAM_UPDATE_PREVENTATIVE_CONTROL = `${prefix}_UPDATE_PREVENTATIVE_CONTROL`;

export const DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT = `${prefix}_ADD_MITIGATING_CONTROLS_INPUT`;
export const DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS = `${prefix}_ADD_MITIGATING_CONTROLS_SUCCESS`;
export const DIAGRAM_REMOVE_MITIGATING_CONTROL = `${prefix}_REMOVE_MITIGATING_CONTROL`;
export const DIAGRAM_UPDATE_MITIGATING_CONTROL = `${prefix}_UPDATE_MITIGATING_CONTROL`;

export const DIAGRAM_ADD_HAZARD_SUCCESS = `${prefix}_ADD_HAZARD_SUCCESS`;

export const DIAGRAM_UNLINK_CONTROL_START = `${prefix}_UNLINK_CONTROL_START`;

export const DIAGRAM_FETCH_RECORDS_BY_FORM_START = `${prefix}_FETCH_RECORDS_BY_FORM_START`;
export const DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS = `${prefix}_FETCH_RECORDS_BY_FORM_SUCCESS`;
export const DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL = `${prefix}_FETCH_RECORDS_BY_FORM_FAIL`;
export const DIAGRAM_RESET_FORM_RECORDS = `${prefix}_RESET_FORM_RECORDS`;

export const DIAGRAM_UPDATE_BOWTIE_SCENARIO = `${prefix}_UPDATE_BOWTIE_SCENARIO`;

export const DIAGRAM_QUEUE_ADD = `${prefix}_QUEUE_ADD`;
export const DIAGRAM_QUEUE_STATUS = `${prefix}_QUEUE_STATUS`;
export const DIAGRAM_QUEUE_UPDATE = `${prefix}_QUEUE_UPDATE`;
export const DIAGRAM_QUEUE_ADD_FAILED = `${prefix}_QUEUE_ADD_FAILED`;
export const DIAGRAM_QUEUE_UPDATE_FAILED = `${prefix}_QUEUE_UPDATE_FAILED`;
export const DIAGRAM_QUEUE_FAIL = `${prefix}_QUEUE_FAIL`;

export const DIAGRAM_LAYOUT = `${prefix}_LAYOUT`;

export const DIAGRAM_SET_MODIFY_BOWTIE_DATA_LOADING = `${prefix}_SET_MODIFY_BOWTIE_DATA_LOADING`;
