import { ReactFlowProvider } from '@xyflow/react';
import AiAnalysisSuggestionContainer from '../../components/common/ai-analysis-suggestion-container';
import Header from '../../components/header/header.component';
import { DiagramContextProvider } from '../../context/diagram.context';
import { DiagramProvider } from '../context/diagram.context';
import Diagram from '../diagram.component';
import useMainHooks from '../hooks/use-main-hooks';
import { initialDiagramConfig } from '../util/data';

const initialStoreValue = { ...initialDiagramConfig, nodes: [], edges: [] };

function MainFlow() {
  const { analysisRef, isAIGeneratedRef, isNewDiagramRef, onAIAnalysisReady } = useMainHooks();

  return (
    <div className="bt-flex bt-flex-col" style={{ width: '100vw', height: '100vh' }}>
      <div className="bt-flex bt-w-full bt-flex-col">
        <DiagramContextProvider isAIGenerated={isAIGeneratedRef.current} isNewDiagram={isNewDiagramRef.current}>
          <Header onAIAnalysisReady={onAIAnalysisReady} />
        </DiagramContextProvider>
        <AiAnalysisSuggestionContainer ref={analysisRef} />
      </div>
      <DiagramProvider {...initialStoreValue}>
        <ReactFlowProvider>
          <Diagram />
        </ReactFlowProvider>
      </DiagramProvider>
    </div>
  );
}

export default MainFlow;
