import React from 'react';
import { cn } from '../../../helpers/util';

export enum ContainerType {
  CAUSES = 'Causes',
  PREVENTATIVE_CONTROLS = 'Preventative Controls',
  CONSEQUENCES = 'Consequences',
  MITIGATING_CONTROLS = 'Mitigating Controls',
  HAZARD = 'Hazard',
  MUE = 'Material Unwanted Event',
}

interface ControlsProps {
  id: string;
  type: ContainerType;
  children: React.ReactNode;
  onAdd?: () => void;
}

const Controls = ({ id, type, children, onAdd }: ControlsProps) => {
  const backgroundClassName = cn(
    { 'bt-border-causes-bg': type === ContainerType.CAUSES },
    { 'bt-border-consequences-bg': type === ContainerType.CONSEQUENCES },
    { 'bt-border-pc-bg': type === ContainerType.PREVENTATIVE_CONTROLS },
    { 'bt-border-mc-bg': type === ContainerType.MITIGATING_CONTROLS }
  );
  const borderClassName = cn(
    { 'bt-border-causes-border': type === ContainerType.CAUSES },
    { 'bt-border-consequences-border': type === ContainerType.CONSEQUENCES },
    { 'bt-border-pc-border': type === ContainerType.PREVENTATIVE_CONTROLS },
    { 'bt-border-mc-border': type === ContainerType.MITIGATING_CONTROLS }
  );
  const dimensionClassName = cn(
    { 'bt-h-[75vh] bt-w-[10vw]': type === ContainerType.CAUSES },
    { 'bt-h-[75vh] bt-w-[10vw]': type === ContainerType.CONSEQUENCES },
    { 'bt-h-[75vh] bt-w-[20vw]': type === ContainerType.PREVENTATIVE_CONTROLS },
    { 'bt-h-[75vh] bt-w-[20vw]': type === ContainerType.MITIGATING_CONTROLS }
  );

  return (
    <div id={`ga-${id}`} className="bt-group">
      <div
        className={cn(
          'bt-mr-4 bt-inline-block bt-rounded-lg bt-border-2 bt-border-dashed bt-px-[22px] bt-py-3 group-last:bt-mr-0',
          dimensionClassName,
          borderClassName
        )}
      >
        <h4 className="bt-mb-16 bt-text-center bt-font-bold bt-text-gray-1">{type}</h4>

        <div className="bt-flex bt-items-center bt-justify-center bt-px-0 bt-py-[130px] bt-text-[8px]">
          <div className={cn('bt-text-center', backgroundClassName)}>
            {onAdd ? (
              <div
                className="bt-relative bt-z-[4000] bt-flex bt-cursor-pointer bt-flex-col bt-items-center bt-justify-center bt-text-center"
                onClick={onAdd}
              >
                <div>
                  <CirclePlus />
                </div>
                <p className="bt-mt-1 bt-w-[80px] bt-text-xs">{children}</p>
              </div>
            ) : (
              <p className="bt-m-auto bt-mt-[35px] bt-w-[55%] bt-text-xs">{children}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;

function CirclePlus() {
  return (
    <svg width="30" height="30" viewBox="0 0 12 12" className="bt-fill-none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5.75" className="bt-fill-mono-1 bt-stroke-cline" strokeWidth="0.5" />
      <line x1="9" y1="5.88806" x2="3" y2="5.88806" className="bt-stroke-cline" strokeWidth="0.5" />
      <line x1="5.78613" y1="3.15625" x2="5.78613" y2="9.15625" className="bt-stroke-cline" strokeWidth="0.5" />
    </svg>
  );
}
