import { Dispatch } from 'redux';
import { DynamicFormSettings } from '../../@types/form-data-types';
import { isSuccessResponse } from '../../helpers/type-guards';
import { BowtieConfiguration } from '../../services/common-data-types';
import { User } from '../../services/user-data-types';
import { UserService } from '../../services/user-service';
import { USER_FETCH_FAILURE, USER_FETCH_LOADING, USER_FETCH_SUCCESS } from './userTypes';

const userService = new UserService('test');

const userActions = {
  doFetchAuthenticatedUserAndSetPermissions:
    (user: User, bowtieConfiguration: BowtieConfiguration) => async (dispatch: Dispatch) => {
      const { prefered_username } = user;

      if (!prefered_username) {
        dispatch({
          type: USER_FETCH_FAILURE,
          payload: 'Username not returned',
        });

        return;
      }

      dispatch({
        type: USER_FETCH_LOADING,
      });

      try {
        const userPayload = await userService.fetchUserByUsername(prefered_username);

        if (isSuccessResponse(userPayload)) {
          const permissions = {
            createRecord: {},
            editRecord: {},
          };

          const user = userPayload.payload.data.items[0];
          const userRoles = user.groups.map((group) => group.caption.translations[0].value);

          Object.keys(bowtieConfiguration).forEach((key) => {
            const form = bowtieConfiguration[key as keyof BowtieConfiguration].form as DynamicFormSettings;
            if (form.createRecordRoles && form.createRecordRoles.length > 0) {
              const formCreateRoles = form.createRecordRoles.map((role) => role.caption);
              const userHasRole = formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

              permissions.createRecord = {
                ...permissions.createRecord,
                [key]: userHasRole,
              };
            } else {
              permissions.createRecord = {
                ...permissions.createRecord,
                [key]: false,
              };
            }
          });

          Object.keys(bowtieConfiguration).forEach((key) => {
            const form = bowtieConfiguration[key as keyof BowtieConfiguration].form;
            if (form.editRecordRoles && form.editRecordRoles.length > 0) {
              const formCreateRoles = form.editRecordRoles.map((role) => role.caption);
              const userHasRole = formCreateRoles.some((formCreateRole) => userRoles.includes(formCreateRole));

              permissions.editRecord = {
                ...permissions.editRecord,
                [key]: userHasRole,
              };
            } else {
              permissions.editRecord = {
                ...permissions.editRecord,
                [key]: false,
              };
            }
          });

          dispatch({
            type: USER_FETCH_SUCCESS,
            payload: {
              id: user.id,
              permissions,
            },
          });
        } else {
          dispatch({
            type: USER_FETCH_FAILURE,
            payload: 'User could not be fetched. Please try again.',
          });
        }
      } catch (err: unknown) {
        dispatch({
          type: USER_FETCH_FAILURE,
          payload: (err as Error).message,
        });
      }
    },
};

export default userActions;
