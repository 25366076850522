import { Button, Loader } from '@myosh/odin-components';
import { cloneDeep } from 'lodash';
import { createContext, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormConfigurationQuery } from '../api/base/v4-api';
import { useBowtieAddonQuery, useBowtieMappingQuery } from '../api/enhanced/enhanced-v4-api';
import { BowtieConfiguration } from '../services/common-data-types';

interface ConfigurationContextProps {
  children: React.ReactNode;
}

const ConfigurationContext = createContext<BowtieConfiguration | undefined>(undefined);

// Provides all information needed for Bowtie
export const ConfigurationProvider: React.FC<ConfigurationContextProps> = ({ children }: ConfigurationContextProps) => {
  const navigate = useNavigate();

  const loading = useRef(true);
  const error = useRef(false);
  const configuration = useRef<BowtieConfiguration>();

  // bowtie addon mapping
  const {
    data: { id: bowtieAddonId, formId: mainFormId } = {},
    isFetching: isBowtieMappingFetching,
    isError: isBowtieMappingError,
  } = useBowtieMappingQuery({});

  // bowtie addon config
  const {
    data: { bowtieConfiguration } = {},
    isFetching: isBowtieConfigFetching,
    isError: isBowtieConfigError,
  } = useBowtieAddonQuery(
    { id: bowtieAddonId! },
    {
      skip: isBowtieMappingFetching || !bowtieAddonId,
    }
  );

  // main form
  const {
    data: mainFormConfig,
    isFetching: isMainFormConfigFetching,
    isError: isMainFormConfigError,
  } = useFormConfigurationQuery(mainFormId!, {
    skip: isBowtieMappingFetching || !mainFormId,
  });

  const mainFormConfigLoadingComplete = !isMainFormConfigFetching && mainFormConfig;

  // controls form
  const {
    data: controlsFormConfig,
    isFetching: isControlsFormConfigFetching,
    isError: isConrolsFormConfigError,
  } = useFormConfigurationQuery(bowtieConfiguration?.controls.formId ?? 0, {
    skip: isBowtieConfigFetching || !bowtieConfiguration?.controls?.formId,
  });

  const controlsFormConfigComlete = !isControlsFormConfigFetching && controlsFormConfig;

  // causes form
  const {
    data: causesFormConfig,
    isFetching: isCausesFormConfigFetching,
    isError: isCausesFormConfigError,
  } = useFormConfigurationQuery(bowtieConfiguration?.causes.formId ?? 0, {
    skip: isBowtieConfigFetching || !bowtieConfiguration?.causes?.formId,
  });

  const causesFormConfigComplete = !isCausesFormConfigFetching && causesFormConfig;

  // consequences form
  const {
    data: consequencesFormConfig,
    isFetching: isConsequencesFormConfigFetching,
    isError: isConsequencesFormConfigError,
  } = useFormConfigurationQuery(bowtieConfiguration?.consequences.formId ?? 0, {
    skip: isBowtieConfigFetching || !bowtieConfiguration?.consequences?.formId,
  });

  const consequencesFormConfigComplete = !isConsequencesFormConfigFetching && consequencesFormConfig;

  const hasError =
    isBowtieMappingError ||
    isBowtieConfigError ||
    isMainFormConfigError ||
    isConrolsFormConfigError ||
    isCausesFormConfigError ||
    isConsequencesFormConfigError;

  if (hasError) {
    error.current = true;
    loading.current = false;
  } else if (
    mainFormConfigLoadingComplete &&
    controlsFormConfigComlete &&
    causesFormConfigComplete &&
    consequencesFormConfigComplete
  ) {
    const config = cloneDeep(bowtieConfiguration);
    config!.main.form = mainFormConfig;
    config!.controls.form = controlsFormConfig;
    config!.causes.form = causesFormConfig;
    config!.consequences.form = consequencesFormConfig;

    configuration.current = config;
    error.current = false;
    loading.current = false;
  }

  const containerStyles =
    'bt-mx-auto bt-my-0 bt-flex bt-h-screen bt-w-full bt-flex-col bt-items-center bt-justify-center';

  if (error.current) {
    return (
      <div className={containerStyles}>
        <span>
          Unable to load the bowtie configuration. Please check your configuration or reload the page to try again.
        </span>
        <Button type="primary" onClick={() => navigate(0)} style={{ marginTop: '12px' }}>
          Reload
        </Button>
      </div>
    );
  } else if (loading.current) {
    return (
      <div className={containerStyles}>
        <Loader title={'Loading diagram configuration'} />
      </div>
    );
  } else {
    return <ConfigurationContext.Provider value={configuration.current}>{children}</ConfigurationContext.Provider>;
  }
};

// context consumer hook
export const useConfigurationContext = () => {
  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error('useConfigurationContext must be used within ConfigurationProvider');
  }

  return context;
};
