import { DomElementAlignment, DomTargetPosition, OdinIcon, OdinIconType, OverlayPanel } from '@myosh/odin-components';
import { memo, useRef, useState } from 'react';
import { cn } from '../../helpers/util';
import filterActions from '../../modules/filter/filterActions';
import filterSelectors from '../../modules/filter/filterSelectors';
import { useAppDispatch, useAppSelector } from '../../modules/hooks';
import { DIAGRAM_MODE } from '../../services/bowtie-data-types';
import AboutModal from './about-modal';

/**
 * Settings component that provides a menu for toggling diagram modes and displaying an About modal.
 *
 * @component
 * @example
 * return (
 *   <Settings />
 * )
 *
 * @returns {JSX.Element} The rendered Settings component.
 */
const Settings = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const menuButtonRef = useRef(null);
  const dispatch = useAppDispatch();
  const diagramMode = useAppSelector(filterSelectors.selectDiagramMode);

  const defaultItems = [
    {
      name: 'Bowtie',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BOWTIE),
      icon: 'Information',
      className: diagramMode === DIAGRAM_MODE.BOWTIE ? 'bt-bg-primary-5' : '',
    },
    {
      name: 'Butterfly',
      action: () => toggleDiagramMode(DIAGRAM_MODE.BUTTERFLY),
      icon: 'MenuFold',
      className: diagramMode === DIAGRAM_MODE.BUTTERFLY ? 'bt-bg-primary-5' : '',
    },
    {
      name: 'About',
      action: () => {
        setIsAboutModalOpen(true);
        setIsMenuOpen(false);
      },
    },
  ];

  const toggleDiagramMode = (selectedDiagramMode: string) => {
    setIsMenuOpen(false);
    dispatch(filterActions.toggleDiagramMode(selectedDiagramMode));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div
        ref={menuButtonRef}
        title="Settings"
        onClick={toggleMenu}
        className="bt-flex bt-items-center bt-text-primary-2 hover:bt-cursor-pointer"
      >
        <OdinIcon icon="Menu" type={OdinIconType.Line} />
      </div>
      <OverlayPanel
        visible={isMenuOpen}
        target={menuButtonRef.current}
        targetPosition={DomTargetPosition.BottomRight}
        elementAlignment={DomElementAlignment.TopRight}
        hidden={() => setIsMenuOpen(false)}
        shouldCheckZIndex
      >
        <div className="bt-mx-auto bt-my-0 bt-min-w-[120px] bt-max-w-fit bt-rounded-lg bt-bg-mono-1 bt-text-left bt-shadow-sm">
          {defaultItems.map((item) => {
            return (
              <div
                key={item.name}
                className={cn(
                  'bt-px-3 bt-py-2 bt-text-base hover:bt-cursor-pointer hover:bt-bg-primary-2 hover:bt-text-mono-1',
                  item.className
                )}
                onClick={item.action}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </OverlayPanel>
      <AboutModal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)} />
    </>
  );
};

export default memo(Settings);
