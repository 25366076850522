import { type Edge, type Node } from '@xyflow/react';
import { CauseDiagramNode, ConsequenceDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { id as causesContainerId } from '../components/container/causes-container-node.component';
import { id as consequencesContainerId } from '../components/container/consequences-container-node.component';
import { id as mitigatingControlsContainerId } from '../components/container/mitigating-controls-container-node.component';
import { id as preventativeControlsContainerId } from '../components/container/preventative-controls-container-node.component';

// spacing constants
const containerStartX = 0,
  containerStartY = 0;

const nodeStartX = 20,
  nodeStartY = 130,
  nodeWidth = 180,
  nodeSpacingY = 50, // vertical spacing between control and edge
  rowSpacingY = 50, // vertical spacing between rows
  nodeDelta = 12;

const baseContainerWidth = 200, // base width to contain a single node
  baseContainerHeight = 450,
  rowHeight = 205, // arbitrary "height" of a row used for calculating container height
  containerSpacingX = 50,
  mueNodeWidth = 208,
  mueNodeHeight = 96; //px

/**
 * Generates nodes and edges configuration for a bow-tie diagram based on the provided diagram configuration.
 *
 * @param data - The diagram configuration containing causes, consequences, MUE (risk scenario), and hazard information
 *
 * @returns An object containing arrays of nodes and edges configured for rendering the bow-tie diagram
 * - nodes: Array of Node objects representing different elements like causes, consequences, controls, and containers
 * - edges: Array of Edge objects representing connections between nodes
 *
 * @remarks
 * The function performs the following:
 * 1. Calculates container sizes based on input data
 * 2. Creates container nodes for causes, consequences, and controls
 * 3. Generates individual nodes for causes, consequences, preventative controls, and mitigating controls
 * 4. Creates central MUE and hazard nodes
 * 5. Establishes edges between nodes to form the bowtie structure
 *
 * @example
 * ```typescript
 * const diagramConfig = {
 *   causes: [...],
 *   consequences: [...],
 *   mue: { id: 'mue1', label: 'Risk Scenario' },
 *   hazard: { id: 'hazard1', label: 'Hazard' }
 * };
 * const { nodes, edges } = generateNodesAndEdges(diagramConfig);
 * ```
 *
 * @typeParam DiagramConfiguration - The input configuration type containing the bow-tie diagram structure
 * @typeParam Node - The node configuration type for the diagram
 * @typeParam Edge - The edge configuration type for the diagram
 */
export const generateNodesAndEdges = (diagramConfig: DiagramConfiguration) => {
  const { mue, hazard, causes, consequences } = diagramConfig;

  // calculate container sizes based on data
  const { controlContainerWidth, controlContainerHeight } = calculateControlContainerSize(causes, consequences);

  /*** NODES ***/
  // Causes
  const causesContainerNode = {
    id: causesContainerId,
    type: 'causes-container',
    position: { x: containerStartX, y: containerStartY },
    data: {}, // data is hardcoded in the causes container node
    style: {
      width: `${baseContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const causesNodes = causes.map((cause, index) => {
    return {
      id: cause.id,
      parentId: causesContainerId,
      type: 'cause-node',
      position: { x: nodeStartX, y: (index + 1) * nodeStartY + (index > 0 ? index * rowSpacingY : 0) },
      data: { label: cause.label, rowIndex: index },
    };
  });

  // Preventative Controls
  const preventativeControlsContainer = {
    id: preventativeControlsContainerId,
    type: 'preventative-controls-container',
    position: { x: containerStartX + baseContainerWidth + containerSpacingX, y: containerStartY },
    data: {}, // data is hardcoded in the preventative controls container node
    style: {
      width: `${controlContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const preventativeControlNodes = causes.map((cause, currentCauseIndex) => {
    return cause.controls.map((control, index) => {
      const mod = index % 2; // modulos to determine top / bottom position
      const div = Math.floor(index / 2); // division to determine the row
      const delta = mod === 0 ? 0 : nodeDelta; // delta to shift the node to the left

      // iterate between top / bottom positions
      const handleTypeKey = mod !== 0 ? 'topHandleType' : 'bottomHandleType';
      const handleIdKey = mod !== 0 ? 'topHandleId' : 'bottomHandleId';
      const handleIdValue = mod !== 0 ? 'top' : 'bottom';

      const causeNodeY =
        (currentCauseIndex + 1) * nodeStartY + (currentCauseIndex > 0 ? currentCauseIndex * rowSpacingY : 0);

      const nodeDeltaY = (mod !== 0 ? 1 : -1) * nodeSpacingY; // delta to shift the node up / down

      return {
        id: control.id,
        parentId: preventativeControlsContainerId,
        type: 'preventative-control-node',
        position: {
          x: controlContainerWidth - nodeWidth - div * nodeWidth - delta,
          y: causeNodeY + nodeDeltaY,
        },
        data: {
          ...control,
          label: control.label,
          rowIndex: currentCauseIndex,
          handles: {
            [handleIdKey]: handleIdValue,
            [handleTypeKey]: 'target',
          },
        },
      };
    });
  });

  // MUE (risk scenario)
  const mueNode = {
    id: mue.id,
    type: 'mue-node',
    position: {
      x: containerStartX + baseContainerWidth + controlContainerWidth + 2 * containerSpacingX,
      y: controlContainerHeight / 2 - mueNodeHeight / 2,
    },
    data: { label: mue.label },
  };

  // Hazard
  const hazardNode = hazard && {
    id: hazard.id,
    type: 'hazard-node',
    position: {
      x: mueNode.position.x + mueNodeWidth / 8,
      y: 0,
    },
    data: { label: hazard.label },
  };

  // mitigating controls
  const mitigatingControlsContainer = {
    id: mitigatingControlsContainerId,
    type: 'mitigating-controls-container',
    position: {
      x: containerStartX + baseContainerWidth + controlContainerWidth + 3 * containerSpacingX + mueNodeWidth,
      y: containerStartY,
    },
    data: {}, // data is hardcoded in the mitigating controls container node
    style: {
      width: `${controlContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const mitigatingControlNodes = consequences.map((consequence, currentConsequenceIndex) => {
    return consequence.controls.map((control, index) => {
      const mod = index % 2; // modulos to determine top / bottom position
      const div = Math.floor(index / 2); // division to determine the row
      const delta = mod === 0 ? 0 : nodeDelta; // delta to shift the node to the right

      // iterate between top / bottom
      const handleTypeKey = mod !== 0 ? 'topHandleType' : 'bottomHandleType';
      const handleIdKey = mod !== 0 ? 'topHandleId' : 'bottomHandleId';
      const handleIdValue = mod !== 0 ? 'top' : 'bottom';

      const consequnceNodeY =
        (currentConsequenceIndex + 1) * nodeStartY +
        (currentConsequenceIndex > 0 ? currentConsequenceIndex * rowSpacingY : 0);

      const nodeDeltaY = (mod !== 0 ? 1 : -1) * nodeSpacingY; // delta to shift the node up / down

      return {
        id: control.id,
        parentId: mitigatingControlsContainerId,
        type: 'mitigating-control-node',
        position: {
          x: div * nodeWidth + nodeStartX + delta,
          y: consequnceNodeY + nodeDeltaY,
        },
        data: {
          ...control,
          label: control.label,
          rowIndex: currentConsequenceIndex,
          handles: {
            [handleIdKey]: handleIdValue,
            [handleTypeKey]: 'target',
          },
        },
      };
    });
  });

  // consequences
  const consequencesContainerNode = {
    id: consequencesContainerId,
    type: 'consequences-container',
    position: {
      x: containerStartX + baseContainerWidth + 2 * controlContainerWidth + 4 * containerSpacingX + mueNodeWidth,
      y: containerStartY,
    },
    data: {}, // data is hardcoded in the consequences container node
    style: {
      width: `${baseContainerWidth}px`,
      height: `${controlContainerHeight}px`,
    },
  };

  const consequencesNodes = consequences.map((consequence, index) => {
    return {
      id: consequence.id,
      parentId: consequencesContainerId,
      type: 'consequence-node',
      position: { x: nodeStartX, y: (index + 1) * nodeStartY + (index > 0 ? index * nodeSpacingY : 0) },
      data: { label: consequence.label, rowIndex: index },
    };
  });
  /*** NODES END ***/

  /*** EDGES ***/
  const hazardMueEdge = hazard && createEdge(hazard.id, mue.id, 'top', 'straight');

  const causesEdges = causesNodes.map((causeNode, index) => {
    const controlNodeIds = preventativeControlNodes[index].map((control) => control.id);
    return createEdge(causeNode.id, mue.id, 'left', 'base-edge', {
      direction: 'left-to-right',
      containerId: preventativeControlsContainerId,
      controls: controlNodeIds,
      rowIndex: index,
    });
  });

  const consequencesEdges = consequencesNodes.map((consequenceNode, index) => {
    const controlNodeIds = mitigatingControlNodes[index].map((control) => control.id);
    return createEdge(consequenceNode.id, mue.id, 'right', 'base-edge', {
      direction: 'right-to-left',
      containerId: mitigatingControlsContainerId,
      controls: controlNodeIds,
      rowIndex: index,
    });
  });
  /*** EDGES END ***/

  // return diagram config of nodes and edges
  const nodes: Array<Node> = [
    causesContainerNode,
    ...causesNodes,
    preventativeControlsContainer,
    ...preventativeControlNodes.flat(),
    mueNode,
    mitigatingControlsContainer,
    ...mitigatingControlNodes.flat(),
    consequencesContainerNode,
    ...consequencesNodes,
  ];

  // add hazard node if hazard exists
  hazardNode && nodes.push(hazardNode);

  const edges: Array<Edge> = [...causesEdges, ...consequencesEdges];

  // add hazard-mue edge if hazard exists
  hazardMueEdge && edges.push(hazardMueEdge);

  return { nodes, edges };
};

/**
 * Creates an edge object representing a connection between two nodes in a flow graph.
 *
 * @param source - The ID of the source node where the edge starts
 * @param target - The ID of the target node where the edge ends
 * @param targetHandle - The handle identifier on the target node where the edge connects
 * @param type - The type of the edge
 * @param data - Optional additional data to be associated with the edge
 * @returns An edge object with generated ID and specified properties
 *
 * @example
 * ```typescript
 * const edge = createEdge('node1', 'node2', 'input1', 'default', { label: 'connection' });
 * ```
 */
const createEdge = (
  source: string,
  target: string,
  targetHandle: string,
  type: string,
  data?: Record<string, unknown>
) => {
  return {
    id: `${source}-${target}`,
    source,
    target,
    targetHandle,
    type,
    data,
  };
};

/**
 * Calculates the dimensions of a control container based on the number of causes and consequences.
 *
 * @param causes - An array of cause diagram nodes, each containing a list of controls
 * @param consequences - An array of consequence diagram nodes, each containing a list of controls
 *
 * @returns An object containing the calculated width and height of the control container
 *   - controlContainerWidth: The width calculated based on the maximum number of controls in a single line
 *   - controlContainerHeight: The height calculated based on the maximum number of rows needed
 */
export const calculateControlContainerSize = (
  causes: Array<CauseDiagramNode>,
  consequences: Array<ConsequenceDiagramNode>
) => {
  // calculate container sizes based on data
  const maxPreventativeControlsInOneLine = Math.max(...causes.map((cause) => cause.controls.length), 1);
  const maxMitigatingControlsInOneLine = Math.max(...consequences.map((consequence) => consequence.controls.length), 1);
  const maxControlsInOneLine = Math.ceil(
    Math.max(maxPreventativeControlsInOneLine, maxMitigatingControlsInOneLine) / 2
  );
  const controlContainerWidth = maxControlsInOneLine * baseContainerWidth;

  const containerMaxRows = Math.max(causes.length, consequences.length, 1);
  const controlContainerHeight = Math.max(baseContainerHeight, containerMaxRows * rowHeight);

  return { controlContainerWidth, controlContainerHeight };
};
