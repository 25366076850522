import LoadingPreview from '../../../components/diagram/shared/loading-preview';
import React from 'react';
import { cn } from '../../../helpers/util';

interface DiagramLoadingProps {
  isLoading?: boolean;
}
interface NodesContainerProps {
  title: string;
  message: string;
  isControl?: boolean;
}

interface HazardRiskNoteProps {
  message: string;
}

const NodesContainer = ({ title, message, isControl = false }: NodesContainerProps) => (
  <div
    className={cn(
      'bt-flex bt-h-full bt-w-full bt-flex-col bt-justify-center bt-rounded-md bt-border-2 bt-border-dashed bt-border-gray-3 bt-px-2 bt-py-4 bt-text-center',
      { 'bt-w-[75%]': !isControl }
    )}
  >
    <div>
      <p className="bt-text-center bt-text-base bt-font-bold">{title}</p>
    </div>
    <div className="bt-flex bt-h-full bt-items-center bt-justify-center">
      <LoadingPreview message={message} />
    </div>
  </div>
);

const HazardRiskNode = ({ message }: HazardRiskNoteProps) => (
  <div className="bt-flex bt-h-20 bt-w-40 bt-items-center bt-justify-center bt-rounded-md bt-border-2 bt-border-dashed bt-border-gray-3 bt-p-2 bt-text-center bt-text-xs">
    <LoadingPreview message={message} />
  </div>
);

const DiagramLoading = ({ isLoading }: DiagramLoadingProps) => (
  <div
    className={cn('bt-absolute bt-z-[1] bt-flex bt-h-full bt-w-full bt-space-x-4 bt-bg-mono-1 bt-px-16 bt-py-12', {
      'bt-hidden': !isLoading,
    })}
  >
    <NodesContainer title="Causes" message="Causes will be displayed here" />
    <NodesContainer
      isControl={true}
      title="Preventative Controls"
      message="Preventative Controls will be displayed here"
    />
    <div className="bt-mx-12 bt-flex bt-h-[55%] bt-w-full bt-flex-col bt-items-center bt-justify-between bt-pt-8">
      <HazardRiskNode message="Hazard will be displayed here" />
      <HazardRiskNode message="Risk will be displayed here" />
    </div>
    <NodesContainer isControl={true} title="Mitigating Controls" message="Mitigating Controls will be displayed here" />
    <NodesContainer title="Consequences" message="Consequences will be displayed here" />
  </div>
);

export default DiagramLoading;
