import { useUser } from '@myosh/myosh-login';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ReactZoomPanPinchRef,
  ReactZoomPanPinchState,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { useLazyBowtieFactorsPostQuery } from '../api/enhanced/enhanced-ai-api';
import { useLazyGetSimpleOptionsForFieldQuery } from '../api/enhanced/enhanced-v4-api';
import AIAnalysisSuggestionContainer, {
  AIAnalysisSuggestionContainerRef,
} from '../components/common/ai-analysis-suggestion-container';
import GeneralErrorContainer from '../components/common/general-error-container';
import LoadingControls from '../components/common/loading-controls.component';
import ZoomControls, { ZoomControlsLoading, ZoomControlsRef } from '../components/common/zoom-controls';
import Diagram from '../components/diagram/diagram';
import Header from '../components/header/header.component';
import { useConfigurationContext } from '../context/configuration.context';
import { DiagramContextProvider } from '../context/diagram.context';
import diagramActions from '../modules/diagram/diagramActions';
import diagramSelectors from '../modules/diagram/diagramSelectors';
import { useAppDispatch, useAppSelector } from '../modules/hooks';
import userActions from '../modules/user/userActions';

export default function Main() {
  const bowtieConfiguration = useConfigurationContext();

  const [initialDiagramScale, setInitialDiagramScale] = useState<number>();
  const [transformComponentMarginTop, setTransformComponentMarginTop] = useState<number>();
  const [transformComponentMarginLeft, setTransformComponentMarginLeft] = useState<number>();
  const [showHazard, setShowHazard] = useState(true);

  const initialDiagramRef = useRef<HTMLDivElement>(null);
  const analysisRef = useRef<AIAnalysisSuggestionContainerRef>(null);
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null);
  const diagramRef = useRef<HTMLDivElement>(null);
  const zoomControlsRef = useRef<ZoomControlsRef>(null);
  const isAIGeneratedRef = useRef(false);
  const isNewDiagramRef = useRef(false);

  const authUserState = useUser();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const queue = useAppSelector(diagramSelectors.selectQueues);
  const loading = useAppSelector(diagramSelectors.selectLoading);
  const loadingModifyBowtieData = useAppSelector(diagramSelectors.selectModifyLoading);

  const errorMessage = useAppSelector(diagramSelectors.selectErrorMessage);
  const bowtieData = useAppSelector(diagramSelectors.selectBowtieData);
  const diagramLayout = useAppSelector(diagramSelectors.selectDiagramLayout);

  const disableDiagram = loading || loadingModifyBowtieData;

  // rtk query
  const [getFactors] = useLazyBowtieFactorsPostQuery();
  const [getControlTypes] = useLazyGetSimpleOptionsForFieldQuery();

  // methods
  const makeHazardVisible = () => setShowHazard(true);

  const onAIAnalysisReady = (text: string) => {
    analysisRef.current?.show(text);
  };

  const setDiagramMargin = (state: ReactZoomPanPinchState) => {
    setTransformComponentMarginTop(state.positionY < 0 ? 0 - state.positionY : 0);
    setTransformComponentMarginLeft(state.positionX < 0 ? 0 - state.positionX : 0);
  };

  // effects
  useEffect(() => {
    if (initialDiagramRef?.current && !initialDiagramScale) {
      setInitialDiagramScale(window.innerWidth / initialDiagramRef.current.clientWidth - 0.06);
    }
  });

  useEffect(() => {
    setShowHazard(searchParams.has('records') || searchParams.has('import'));
  }, []);

  useEffect(() => {
    if (queue?.tasks?.length > 0) {
      if (!queue.isQueuing) {
        dispatch(diagramActions.doUpdateQueueStatus(true));
        const { payload, type } = queue.tasks[0];

        if (isAIGeneratedRef.current === true) {
          if (type === 'preventative_control') {
            dispatch(diagramActions.addPreventativeControlsToAIDiagram(payload));
          } else {
            dispatch(diagramActions.addMitigatingControlsToAIDiagram(payload));
          }
        } else {
          if (type === 'preventative_control') {
            dispatch(diagramActions.doAddPreventativeControls(payload));
          } else {
            dispatch(diagramActions.doAddMitigatingControls(payload));
          }
        }
      }
    }
  }, [queue?.tasks, queue?.isQueuing]);

  useEffect(() => {
    if (queue?.failed?.length > 0) {
      if (!queue.isRemovingQueue) {
        dispatch(diagramActions.doUpdateFailedQueueStatus(true));
        const { payload, type } = queue.failed[0];
        const { id, parentId } = payload;
        const data = {
          id,
          parentId,
        };

        if (type === 'preventative_control') {
          dispatch(diagramActions.doRemovePreventativeControl(data));
        } else {
          dispatch(diagramActions.doRemoveMitigatingControl(data));
        }
      }
    }
  }, [queue?.failed, queue?.isRemovingQueue]);

  useEffect(() => {
    if (!!authUserState?.state?.user) {
      dispatch(userActions.doFetchAuthenticatedUserAndSetPermissions(authUserState.state.user, bowtieConfiguration));
    }
  }, [authUserState?.state?.user]);

  useEffect(() => {
    if (searchParams.has('term') && searchParams.has('hazard') && searchParams.has('mue')) {
      isAIGeneratedRef.current = true;
      isNewDiagramRef.current = false;
      makeHazardVisible();

      const term = searchParams.get('term')!;
      const hazard = searchParams.get('hazard')!;
      const mue = searchParams.get('mue')!;

      const { controlTypeFieldId, formId } = bowtieConfiguration.controls;
      // retrieve the control types list
      getControlTypes({ fieldId: controlTypeFieldId, formId: formId, limit: -1 })
        .unwrap()
        .catch(() => undefined)
        .then((controlTypes) => {
          // generate the AI diagram data
          return getFactors({
            factorRequest: {
              term,
              hazard,
              selected_MUE: mue,
              control_types: controlTypes,
            },
          }).unwrap();
        })
        .then((data) => {
          // copy over the forms configuration
          const bowtieAIData = {
            bowtieConfiguration: bowtieConfiguration,
            causes: [...data.causes],
            consequences: [...data.consequences],
            scenario: mue,
            hazard: data.hazard,
          };

          // populate store with the AI data
          dispatch(diagramActions.doSetBowtieData(bowtieAIData));
        })
        .catch(() => {
          toast.error('Unable to generate bowtie, please try again later.');
        });
    } else if (searchParams.has('import')) {
      const diagramId = searchParams.get('import');
      isAIGeneratedRef.current = true;
      isNewDiagramRef.current = false;
      const importedDiagram = localStorage.getItem(`importedDiagram-${diagramId}`);
      importedDiagram && dispatch(diagramActions.doSetBowtieData(JSON.parse(importedDiagram)));
      localStorage.removeItem(`importedDiagram-${diagramId}`);
    } else if (searchParams.has('records')) {
      isAIGeneratedRef.current = false;
      isNewDiagramRef.current = false;
      const recordId = searchParams.get('records')!;
      dispatch(diagramActions.doFetchAndTransformRecord(recordId, bowtieConfiguration));
    } else {
      isAIGeneratedRef.current = false;
      isNewDiagramRef.current = true;
      dispatch(diagramActions.doSetLoading(false));
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      diagramLayout?.isWidth &&
      zoomControlsRef.current &&
      zoomControlsRef.current.getZoomPercentage() === 100 &&
      transformWrapperRef?.current &&
      diagramRef?.current &&
      window?.innerWidth
    ) {
      const transformScale = window.innerWidth / diagramRef.current.clientWidth - 0.06;

      setInitialDiagramScale(transformScale);
      transformWrapperRef.current.state.scale = transformScale;
      transformWrapperRef.current.centerView();
      setDiagramMargin(transformWrapperRef.current.state);
      dispatch(
        diagramActions.doUpdateDiagramLayout({
          isWidth: false,
        })
      );
    }
  }, [diagramLayout?.isWidth]);

  return (
    <DiagramContextProvider isAIGenerated={isAIGeneratedRef.current} isNewDiagram={isNewDiagramRef.current}>
      <Helmet>
        <title>
          {bowtieData?.scenario ? `${bowtieData?.scenario} | Bowtie by myosh` : 'New Diagram | Bowtie by myosh'}
        </title>
      </Helmet>
      <div className="bt-flex bt-h-full bt-flex-col">
        <Header onAIAnalysisReady={onAIAnalysisReady} />
        <AIAnalysisSuggestionContainer ref={analysisRef} />

        <div className="bt-flex bt-h-full bt-flex-col bt-overflow-hidden bt-pb-3">
          {errorMessage && errorMessage.length > 0 ? (
            <GeneralErrorContainer errorMessage="An error occurred" errorDescription={errorMessage} />
          ) : (
            <div className="bt-flex bt-h-full bt-flex-col">
              {/* invisible layer to block diagram action */}
              {disableDiagram && <div className="bt-absolute bt-z-[4001] bt-h-full bt-w-full bt-bg-transparent" />}
              {disableDiagram && (
                <div className="bt-absolute bt-z-[4002] bt-flex bt-h-4 bt-w-full bt-flex-row bt-items-center bt-justify-center bt-gap-2 bt-px-12 bt-py-8">
                  <LoadingControls />
                  <p className="bt-mb-[revert]">Loading</p>
                </div>
              )}
              {loading ? (
                <>
                  <ZoomControlsLoading />
                  <div className="bt-mt-3">
                    <Diagram isLoading showHazard={showHazard} makeHazardVisible={makeHazardVisible} />
                  </div>
                </>
              ) : initialDiagramScale ? (
                <TransformWrapper
                  ref={transformWrapperRef}
                  initialScale={initialDiagramScale}
                  wheel={{ wheelDisabled: true, disabled: true }}
                  minScale={0.1}
                  maxScale={6}
                  centerOnInit={true}
                  doubleClick={{
                    disabled: true,
                  }}
                  panning={{
                    disabled: true,
                    excluded: ['textarea', 'svg', 'main-area-container', 'hazard-container'],
                  }}
                  onZoomStop={(event) => {
                    setDiagramMargin(event.state);
                  }}
                >
                  {({ zoomIn, zoomOut, resetTransform, state }) => {
                    // if removed the zoom out doesn't always work correctly (we need to fix this)
                    setTimeout(() => setDiagramMargin(state));

                    // zoom control handlers
                    const handleZoomIn = () => {
                      zoomIn();
                      setTimeout(() => {
                        setDiagramMargin(state);
                      }, 300);
                    };

                    const handleZoomOut = () => {
                      zoomOut();
                      setTimeout(() => {
                        setDiagramMargin(state);
                      }, 300);
                    };

                    const handleZoomReset = () => {
                      setTransformComponentMarginLeft(undefined);
                      setTransformComponentMarginTop(undefined);
                      setDiagramMargin(state);
                      resetTransform();
                    };

                    return (
                      <>
                        <ZoomControls
                          ref={zoomControlsRef}
                          zoomIn={handleZoomIn}
                          zoomOut={handleZoomOut}
                          zoomReset={handleZoomReset}
                        />
                        <TransformComponent
                          wrapperStyle={{
                            overflow: 'auto',
                            zIndex: 1000,
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                          }}
                          wrapperClass="diagram-transform-container custom-scroll"
                          contentStyle={{
                            marginTop: transformComponentMarginTop ? `${transformComponentMarginTop}px` : 'unset',
                            marginLeft: transformComponentMarginLeft ? `${transformComponentMarginLeft}px` : 'unset',
                          }}
                        >
                          <Diagram
                            ref={diagramRef}
                            isLoading={false}
                            showHazard={showHazard}
                            makeHazardVisible={makeHazardVisible}
                          />
                        </TransformComponent>
                      </>
                    );
                  }}
                </TransformWrapper>
              ) : (
                // This only exists to calculate the 'initialScale' for the <TransformWrapper> component
                <div className="bt-invisible bt-absolute bt-left-0 bt-top-0">
                  <Diagram
                    ref={initialDiagramRef}
                    isLoading={false}
                    showHazard={showHazard}
                    makeHazardVisible={makeHazardVisible}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DiagramContextProvider>
  );
}
