import { isErrorResponse, isObject, isSuccessResponse } from '../../helpers/type-guards';
import { BowtieService } from '../../services/bowtie-service';
import { RecordService } from '../../services/record-service';
import DiagramService from './diagramService';
import {
  DIAGRAM_ADD_CAUSE_INPUT,
  DIAGRAM_ADD_CAUSE_SUCCESS,
  DIAGRAM_ADD_CONSEQUENCE_INPUT,
  DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
  DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
  DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
  DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
  DIAGRAM_FETCH_RECORD_FAIL,
  DIAGRAM_FETCH_RECORD_START,
  DIAGRAM_FETCH_RECORD_SUCCESS,
  DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
  DIAGRAM_FETCH_RECORDS_BY_FORM_START,
  DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
  DIAGRAM_LAYOUT,
  DIAGRAM_QUEUE_ADD,
  DIAGRAM_QUEUE_ADD_FAILED,
  DIAGRAM_QUEUE_FAIL,
  DIAGRAM_QUEUE_STATUS,
  DIAGRAM_QUEUE_UPDATE,
  DIAGRAM_QUEUE_UPDATE_FAILED,
  DIAGRAM_REMOVE_CAUSE,
  DIAGRAM_REMOVE_CONSEQUENCE,
  DIAGRAM_REMOVE_MITIGATING_CONTROL,
  DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
  DIAGRAM_RESET_FORM_RECORDS,
  DIAGRAM_SET_LOADING,
  DIAGRAM_SET_MODIFY_BOWTIE_DATA_LOADING,
  DIAGRAM_UPDATE_BOWTIE_SCENARIO,
  DIAGRAM_UPDATE_CAUSE,
  DIAGRAM_UPDATE_CONSEQUENCE,
  DIAGRAM_UPDATE_MITIGATING_CONTROL,
  DIAGRAM_UPDATE_PREVENTATIVE_CONTROL,
} from './diagramTypes';

const diagramService = new DiagramService();
const recordService = new RecordService('test');
const bowtieService = new BowtieService('test');

const doResetFormRecords = () => async (dispatch) => {
  dispatch({ type: DIAGRAM_RESET_FORM_RECORDS });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doFetchRecordsByFormId = (formId, query) => async (dispatch) => {
  try {
    dispatch({
      type: DIAGRAM_FETCH_RECORDS_BY_FORM_START,
    });

    const recordsPayload = await recordService.fetchFilteredRecordsByFormId(formId, query.filter);

    if (isSuccessResponse(recordsPayload)) {
      const items = recordsPayload.payload.data?.result?.results;
      dispatch({
        type: DIAGRAM_FETCH_RECORDS_BY_FORM_SUCCESS,
        payload: items,
      });
    } else {
      dispatch({
        type: DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
        payload: 'Internal Server Error',
      });
    }
  } catch (err) {
    dispatch({
      type: DIAGRAM_FETCH_RECORDS_BY_FORM_FAIL,
      payload: err.message,
    });
  }
};

const doFetchAndTransformRecord =
  (recordId, bowtieConfiguration, disableGlobalLoading = false) =>
  async (dispatch, getState) => {
    try {
      const recordPayload = await recordService.fetchRecord(recordId);
      const bowtieDataState = getState().diagram.bowtieData;

      if (isErrorResponse(recordPayload)) {
        dispatch({
          type: DIAGRAM_FETCH_RECORD_FAIL,
          payload: recordPayload.payload.message,
        });
      } else {
        const record = recordPayload.payload.data.result.results[0];

        if (!disableGlobalLoading) {
          dispatch({ type: DIAGRAM_FETCH_RECORD_START });
        }

        try {
          const bowtieData = await bowtieService.mapBowtieData(record, bowtieConfiguration);

          const data = {
            ...bowtieDataState,
            ...bowtieData,
          };
          dispatch(diagramActions.doSetBowtieData(data));
        } catch (err) {
          dispatch({
            type: DIAGRAM_FETCH_RECORD_FAIL,
            payload: err.message,
          });
        }
      }
    } catch (err) {
      dispatch({
        type: DIAGRAM_FETCH_RECORD_FAIL,
        payload: err.message,
      });
    }
  };

const doAddCauseInput = () => async (dispatch) => {
  const causePayload = {
    id: crypto.randomUUID(),
    value: null,
    linkUrl: null,
    published: false,
    preventativeControls: [],
  };

  dispatch({ type: DIAGRAM_ADD_CAUSE_INPUT, payload: causePayload });
};

const doAddConsequenceInput = () => async (dispatch) => {
  const consequencePayload = {
    id: crypto.randomUUID(),
    value: null,
    linkUrl: null,
    published: false,
    mitigatingControls: [],
  };

  dispatch({
    type: DIAGRAM_ADD_CONSEQUENCE_INPUT,
    payload: consequencePayload,
  });
};

const doAddMitigatingControlInput = (payload) => async (dispatch) => {
  const mitigatingPayload = {
    id: payload.placeholderId || crypto.randomUUID(),
    value: payload.value,
    linkUrl: null,
    published: false,
    consequenceId: payload.index,
  };

  dispatch({
    type: DIAGRAM_ADD_MITIGATING_CONTROLS_INPUT,
    payload: mitigatingPayload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddPreventativeControlInput = (payload) => async (dispatch) => {
  const preventativePayload = {
    id: payload.placeholderId || crypto.randomUUID(),
    value: payload.value,
    linkUrl: null,
    published: false,
    causeId: payload.index,
  };

  dispatch({
    type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_INPUT,
    payload: preventativePayload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddCause = (cause) => async (dispatch, getState) => {
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    const causePayload = {
      id: cause.id,
      value: cause.value,
      linkUrl: null,
      published: false,
      preventativeControls: [],
    };
    dispatch(doSetModifyBowtieDataLoading(true));

    dispatch(
      doUpdateDiagramLayout({
        isWidth: true,
      })
    );

    const causeResponse = await recordService.addCauseConsequence(
      causePayload.value,
      bowtieConfiguration.causes.formId,
      bowtieConfiguration.causes.captionField,
      bowtieConfiguration.causes.form,
      bowtieData.scenarioRecord.id
    );

    if (isSuccessResponse(causeResponse)) {
      causePayload.published = true;
      const fetchedCause = await recordService.fetchRecord(causeResponse.payload.data.result[0]?.id);
      if (isSuccessResponse(fetchedCause)) {
        const result = fetchedCause.payload.data.result.results[0];
        if (result) {
          causePayload.uuid = result.id;
          causePayload.linkUrl = result.linkUrl;
          causePayload.status = result.status;
        }
      }

      const standaloneCauses = localStorage.getItem('standaloneCauses');
      if (!standaloneCauses) {
        localStorage.setItem('standaloneCauses' + '_' + window.location.search, JSON.stringify([causePayload]));
      } else {
        const parsedStandaloneCauses = JSON.parse(standaloneCauses);
        localStorage.setItem(
          'standaloneCauses' + '_' + window.location.search,
          JSON.stringify([...parsedStandaloneCauses, causePayload])
        );
      }
      dispatch({ type: DIAGRAM_ADD_CAUSE_SUCCESS, payload: causePayload });
    }
    dispatch(doSetModifyBowtieDataLoading(false));
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false));
  }
};

const addCauseToAIDiagram = (cause) => async (dispatch) => {
  const causePayload = {
    id: cause.id,
    uuid: cause.id,
    value: cause.value,
    linkUrl: null,
    published: false,
    preventativeControls: [],
  };

  dispatch({ type: DIAGRAM_ADD_CAUSE_SUCCESS, payload: causePayload });
};

const updateCauseInDiagram = (cause) => async (dispatch) => {
  dispatch({ type: DIAGRAM_UPDATE_CAUSE, payload: cause });
};

const doRemoveCause = (id) => async (dispatch) => {
  dispatch({ type: DIAGRAM_REMOVE_CAUSE, payload: id });
};

const doAddConsequence = (consequence) => async (dispatch, getState) => {
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    const consequencePayload = {
      id: consequence.id,
      value: consequence.value,
      linkUrl: null,
      published: false,
      mitigatingControls: [],
    };

    dispatch(doSetModifyBowtieDataLoading(true));

    dispatch(
      doUpdateDiagramLayout({
        isWidth: true,
      })
    );

    const consequenceResponse = await recordService.addCauseConsequence(
      consequencePayload.value,
      bowtieConfiguration.consequences.formId,
      bowtieConfiguration.consequences.captionField,
      bowtieConfiguration.consequences.form,
      bowtieData.scenarioRecord.id
    );

    if (isSuccessResponse(consequenceResponse)) {
      consequenceResponse.published = true;
      const fetchedConsequence = await recordService.fetchRecord(consequenceResponse.payload.data.result[0]?.id);
      if (isSuccessResponse(fetchedConsequence)) {
        const result = fetchedConsequence.payload.data.result.results[0];
        if (result) {
          consequencePayload.uuid = result.id;
          consequencePayload.linkUrl = result.linkUrl;
          consequencePayload.status = result.status;
        }
      }

      dispatch({
        type: DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
        payload: consequencePayload,
      });

      const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);
      if (!standaloneConsequences) {
        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify([consequencePayload])
        );
      } else {
        const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);
        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify([...parsedStandaloneConsequences, consequencePayload])
        );
      }
    } else {
      dispatch(doSetModifyBowtieDataLoading(false, 'Consequence was not added. Please try again.'));
    }
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));
  }
};

const addConsequenceToAIDiagram = (consequence) => async (dispatch) => {
  const consequencePayload = {
    id: consequence.id,
    uuid: consequence.id,
    value: consequence.value,
    linkUrl: null,
    published: false,
    mitigatingControls: [],
  };

  dispatch({
    type: DIAGRAM_ADD_CONSEQUENCE_SUCCESS,
    payload: consequencePayload,
  });
};

const updateConsequenceInDiagram = (consequence) => async (dispatch) => {
  dispatch({ type: DIAGRAM_UPDATE_CONSEQUENCE, payload: consequence });
};

const doRemoveConsequence = (id) => async (dispatch) => {
  dispatch({ type: DIAGRAM_REMOVE_CONSEQUENCE, payload: id });
};

const doAddPreventativeControls = (preventativeControls) => async (dispatch, getState) => {
  const state = getState();
  const type = 'preventative_control';

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
      bowtieData: {
        bowtieConfiguration: { controls },
      },
    },
  } = state;

  const sourceId = crypto.randomUUID();

  try {
    const pcPayload = {
      id: preventativeControls.id,
      value: preventativeControls.value,
      linkUrl: null,
      published: false,
      parentId: preventativeControls.parentId,
    };

    dispatch(doSetModifyBowtieDataLoading(true));

    const preventativeControlPayload = !preventativeControls.existingElement
      ? await recordService.addControl(
          sourceId,
          preventativeControls.value,
          bowtieConfiguration.controls.formId,
          preventativeControls.parentId,
          bowtieConfiguration.controls.captionField,
          bowtieConfiguration.controls.form,
          true
        )
      : await recordService.updateControl(
          preventativeControls.existingElement.id,
          preventativeControls.value,
          bowtieConfiguration.controls.formId,
          preventativeControls.parentId,
          bowtieConfiguration.controls.captionField,
          preventativeControls.existingElement,
          true
        );

    let fields;

    if (!!bowtieData?.scenarioRecord?.fields) {
      fields = bowtieData?.scenarioRecord?.fields;
    } else {
      const record = await recordService.fetchRecord(bowtieData?.scenarioRecord?.id);
      fields = isSuccessResponse(record) ? record.payload.data.result.results[0].fields : {};
    }

    if (isSuccessResponse(preventativeControlPayload)) {
      await diagramService.updateRiskScenarioRecord(bowtieData.scenarioRecord.id, {
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        preventative: true,
        fields,
        linkedRecordId: preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id,
      });

      const fetchedPreventativeControl = await recordService.fetchRecord(
        preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id
      );

      const result = isSuccessResponse(fetchedPreventativeControl)
        ? fetchedPreventativeControl.payload.data.result.results[0]
        : {};

      pcPayload.published = true;
      pcPayload.id = Number(
        preventativeControls.existingElement
          ? preventativeControls.existingElement.id
          : preventativeControlPayload.payload.data.result[0]?.id
      );
      pcPayload.linkUrl = preventativeControls.existingElement
        ? preventativeControls.existingElement.linkUrl
        : result.linkUrl;
      pcPayload.status = preventativeControls.existingElement
        ? preventativeControls.existingElement.status
        : result.status;

      const criticalOrNonCriticalField = result.fields[controls.criticalOrNonCriticalField];
      pcPayload.criticalOrNonCritical =
        criticalOrNonCriticalField && isObject(criticalOrNonCriticalField) && criticalOrNonCriticalField.value;

      const effectiveOrNotEffectiveField =
        result[controls.effectiveOrNotEffectiveField] || result.fields[controls.effectiveOrNotEffectiveField];
      pcPayload.effectiveOrNotEffective =
        effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
          ? effectiveOrNotEffectiveField.value
          : { value: effectiveOrNotEffectiveField };

      const standaloneCauses = localStorage.getItem('standaloneCauses' + '_' + window.location.search);

      if (standaloneCauses) {
        const parsedStandaloneCauses = JSON.parse(standaloneCauses);

        localStorage.setItem(
          'standaloneCauses' + '_' + window.location.search,
          JSON.stringify(parsedStandaloneCauses.filter((cause) => cause.uuid !== preventativeControls.parentId))
        );
      }

      dispatch(
        diagramActions.doFetchAndTransformRecord(bowtieData.scenarioRecord.id, bowtieData.bowtieConfiguration, true)
      );
      dispatch(doSetModifyBowtieDataLoading(false));

      dispatch(doUpdateQueue());
    } else {
      dispatch(doSetModifyBowtieDataLoading(false, 'Preventative Control was not added. Please try again later.'));

      dispatch(doUpdateQueue());
      dispatch(
        doAddInFailedQueue({
          payload: preventativeControls,
          type,
        })
      );
    }
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));

    dispatch(doUpdateQueue());
    dispatch(
      doAddInFailedQueue({
        payload: preventativeControls,
        type,
      })
    );
  }
};

const addPreventativeControlsToAIDiagram = (preventativeControls) => async (dispatch) => {
  // simulates the add of a preventative control, only for the diagram
  dispatch({
    type: DIAGRAM_ADD_PREVENTATIVE_CONTROLS_SUCCESS,
    payload: {
      pcPayload: { ...preventativeControls },
      global: false,
    },
  });

  dispatch(doUpdateQueue());
};

const updatePreventativeControlInDiagram = (preventativeControl) => (dispatch) => {
  dispatch({
    type: DIAGRAM_UPDATE_PREVENTATIVE_CONTROL,
    payload: {
      ...preventativeControl,
    },
  });

  dispatch(doUpdateQueue());
};

const doRemovePreventativeControl = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_REMOVE_PREVENTATIVE_CONTROL,
    payload,
  });
  dispatch(doUpdateFailedQueue());
};

const doRemoveMitigatingControl = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_REMOVE_MITIGATING_CONTROL,
    payload,
  });
  dispatch(doUpdateFailedQueue());
};

const doAddMitigatingControls = (mitigatingControls) => async (dispatch, getState) => {
  const state = getState();
  const type = 'mitigating_control';

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
      bowtieData: {
        bowtieConfiguration: { controls },
      },
    },
  } = state;

  const sourceId = crypto.randomUUID();

  try {
    const mcPayload = {
      id: mitigatingControls.id,
      value: mitigatingControls.value,
      linkUrl: null,
      published: false,
      parentId: mitigatingControls.parentId,
    };

    dispatch(doSetModifyBowtieDataLoading(true));

    const mitigatingControlPayload = !mitigatingControls.existingElement
      ? await recordService.addControl(
          sourceId,
          mitigatingControls.value,
          bowtieConfiguration.controls.formId,
          mitigatingControls.parentId,
          bowtieConfiguration.controls.captionField,
          bowtieConfiguration.controls.form,
          false
        )
      : await recordService.updateControl(
          mitigatingControls.existingElement.id,
          mitigatingControls.value,
          bowtieConfiguration.controls.formId,
          mitigatingControls.parentId,
          bowtieConfiguration.controls.captionField,
          mitigatingControls.existingElement,
          false
        );

    let fields;

    if (!!bowtieData?.scenarioRecord?.fields) {
      fields = bowtieData?.scenarioRecord?.fields;
    } else {
      const record = await recordService.fetchRecord(bowtieData?.scenarioRecord?.id);
      fields = isSuccessResponse(record) ? record.payload.data.result.results[0].fields : {};
    }

    if (isSuccessResponse(mitigatingControlPayload)) {
      await diagramService.updateRiskScenarioRecord(bowtieData.scenarioRecord.id, {
        formId: bowtieData.scenarioRecord.formId,
        formName: bowtieData.scenarioRecord.formName,
        status: bowtieData.scenarioRecord.status,
        mitigating: true,
        fields,
        linkedRecordId: mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0]?.id,
      });

      const fetchedMitigatingControl = await recordService.fetchRecord(
        mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0]?.id
      );

      const result = isSuccessResponse(fetchedMitigatingControl)
        ? fetchedMitigatingControl.payload.data.result.results[0]
        : {};

      mcPayload.published = true;
      mcPayload.id = Number(
        mitigatingControls.existingElement
          ? mitigatingControls.existingElement.id
          : mitigatingControlPayload.payload.data.result[0].id
      );
      mcPayload.linkUrl = mitigatingControls.existingElement
        ? mitigatingControls.existingElement.linkUrl
        : result.linkUrl;
      mcPayload.status = mitigatingControls.existingElement ? mitigatingControls.existingElement.status : result.status;

      const criticalOrNonCriticalField = result.fields[controls.criticalOrNonCriticalField];
      mcPayload.criticalOrNonCritical =
        criticalOrNonCriticalField && isObject(criticalOrNonCriticalField) && criticalOrNonCriticalField.value;

      const effectiveOrNotEffectiveField =
        result[controls.effectiveOrNotEffectiveField] || result.fields[controls.effectiveOrNotEffectiveField];
      mcPayload.effectiveOrNotEffective =
        effectiveOrNotEffectiveField && isObject(effectiveOrNotEffectiveField)
          ? effectiveOrNotEffectiveField.value
          : { value: effectiveOrNotEffectiveField };

      const standaloneConsequences = localStorage.getItem('standaloneConsequences' + '_' + window.location.search);

      if (standaloneConsequences) {
        const parsedStandaloneConsequences = JSON.parse(standaloneConsequences);

        localStorage.setItem(
          'standaloneConsequences' + '_' + window.location.search,
          JSON.stringify(
            parsedStandaloneConsequences.filter((consequence) => consequence.uuid !== mitigatingControls.parentId)
          )
        );
      }

      dispatch(
        diagramActions.doFetchAndTransformRecord(bowtieData.scenarioRecord.id, bowtieData.bowtieConfiguration, true)
      );
      dispatch(doSetModifyBowtieDataLoading(false));

      dispatch(doUpdateQueue());
    } else {
      dispatch(doSetModifyBowtieDataLoading(false, 'Mitigating Control was not added. Please try again later.'));

      dispatch(doUpdateQueue());
      dispatch(
        doAddInFailedQueue({
          payload: mitigatingControls,
          type,
        })
      );
    }
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));

    dispatch(doUpdateQueue());
    dispatch(
      doAddInFailedQueue({
        payload: mitigatingControls,
        type,
      })
    );
  }
};

const addMitigatingControlsToAIDiagram = (mitigatingControls) => async (dispatch) => {
  // simulates the add of a mitigating control, only for the diagram
  dispatch({
    type: DIAGRAM_ADD_MITIGATING_CONTROLS_SUCCESS,
    payload: {
      mcPayload: { ...mitigatingControls },
      global: false,
    },
  });

  dispatch(doUpdateQueue());
};

const updateMitigatingControlsInDiagram = (mitigatingControl) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_UPDATE_MITIGATING_CONTROL,
    payload: {
      ...mitigatingControl,
    },
  });

  dispatch(doUpdateQueue());
};

const doUpdateRiskScenarioValue = (bowtieData, payload, shouldExecuteUpdate) => async (dispatch) => {
  try {
    dispatch({
      type: DIAGRAM_UPDATE_BOWTIE_SCENARIO,
      payload,
    });

    if (shouldExecuteUpdate) {
      const response = await diagramService.updateRiskScenarioField(bowtieData, payload, dispatch);

      if (response.data.result) {
        dispatch(doSetModifyBowtieDataLoading(false));
      } else {
        dispatch(doSetModifyBowtieDataLoading(false, 'Risk Scenario was not updated. Please try again.'));
      }
    } else {
      dispatch(doSetModifyBowtieDataLoading(false));
    }
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));
  }
};

const doAddRiskScenario = (params) => async (dispatch) => {
  const { payload, urlParsed, bowtieConfiguration, navigate } = params;

  try {
    dispatch(doSetModifyBowtieDataLoading(true));

    const riskScenarioRecordPayload = await diagramService.addRiskScenario(
      bowtieConfiguration.main.form,
      bowtieConfiguration.main.scenarioCaptionField,
      payload
    );

    if (riskScenarioRecordPayload.success) {
      const recordId = riskScenarioRecordPayload.payload.data.result[0].id;

      // urlParsed
      navigate(`./?${urlParsed.length > 0 ? `${urlParsed}&` : ''}records=${recordId}`);

      dispatch(doSetModifyBowtieDataLoading(false));
    } else {
      dispatch(doSetModifyBowtieDataLoading(false, 'Risk Scenario was not added. Please try again.'));
    }
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));
  }
};

const doAddHazard = (params) => async (dispatch, getState) => {
  const { payload, onHazardDisabled, onEditHazard } = params;
  const state = getState();

  const {
    diagram: {
      bowtieData,
      bowtieData: { bowtieConfiguration },
    },
  } = state;

  try {
    dispatch(doSetModifyBowtieDataLoading(true));

    const addHazardPayload = await diagramService.updateRiskScenarioHazard(
      bowtieData.scenarioRecord,
      bowtieConfiguration.main.hazardCaptionField,
      payload,
      dispatch
    );

    if (addHazardPayload.result) {
      dispatch(
        diagramActions.doFetchAndTransformRecord(bowtieData.scenarioRecord.id, bowtieData.bowtieConfiguration, true)
      );
      dispatch(doSetModifyBowtieDataLoading(false));
    } else {
      dispatch(doSetModifyBowtieDataLoading(false, 'Hazard was not added. Please try again.'));
    }

    onHazardDisabled(false);
    onEditHazard(false);
  } catch (err) {
    dispatch(doSetModifyBowtieDataLoading(false, err.message));
  }
};

const doAddInQueue = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_ADD,
    payload,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doAddInFailedQueue = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_ADD_FAILED,
    payload,
  });
};

const doUpdateQueueStatus = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_STATUS,
    payload,
  });
};

const doUpdateFailedQueueStatus = (payload) => async (dispatch) => {
  dispatch({
    type: DIAGRAM_QUEUE_FAIL,
    payload,
  });
};

const doUpdateQueue = () => async (dispatch, getState) => {
  const newState = getState();
  const tasks = [...newState?.diagram?.queue?.tasks];
  tasks.splice(0, 1);

  dispatch({
    type: DIAGRAM_QUEUE_UPDATE,
    payload: tasks,
  });
  dispatch(
    doUpdateDiagramLayout({
      isWidth: true,
    })
  );
};

const doUpdateFailedQueue = () => async (dispatch, getState) => {
  const newState = getState();
  const failed = [...newState?.diagram?.queue?.failed];
  failed.splice(0, 1);

  dispatch({
    type: DIAGRAM_QUEUE_UPDATE_FAILED,
    payload: failed,
  });
};

const doUpdateDiagramLayout = (payload) => (dispatch) => {
  dispatch({
    type: DIAGRAM_LAYOUT,
    payload,
  });
};

const doSetModifyBowtieDataLoading = (loading, message) => (dispatch) => {
  dispatch({
    type: DIAGRAM_SET_MODIFY_BOWTIE_DATA_LOADING,
    payload: { loading, message },
  });
};

const doSetBowtieData = (data) => (dispatch) => {
  dispatch({
    type: DIAGRAM_FETCH_RECORD_SUCCESS,
    payload: data,
  });
};

const doSetLoading = (loading) => (dispatch) => {
  dispatch({
    type: DIAGRAM_SET_LOADING,
    payload: loading,
  });
};

const diagramActions = {
  doFetchAndTransformRecord,
  doAddCause,
  addCauseToAIDiagram,
  updateCauseInDiagram,
  doAddCauseInput,
  doAddConsequence,
  addConsequenceToAIDiagram,
  updateConsequenceInDiagram,
  doAddConsequenceInput,
  doAddPreventativeControlInput,
  doAddMitigatingControlInput,
  doAddPreventativeControls,
  addPreventativeControlsToAIDiagram,
  updatePreventativeControlInDiagram,
  doAddMitigatingControls,
  addMitigatingControlsToAIDiagram,
  updateMitigatingControlsInDiagram,
  doAddRiskScenario,
  doAddHazard,
  doRemoveCause,
  doRemoveConsequence,
  doRemoveMitigatingControl,
  doRemovePreventativeControl,
  doFetchRecordsByFormId,
  doResetFormRecords,
  doUpdateRiskScenarioValue,
  doAddInQueue,
  doUpdateQueueStatus,
  doUpdateQueue,
  doUpdateFailedQueueStatus,
  doAddInFailedQueue,
  doUpdateFailedQueue,
  doUpdateDiagramLayout,
  doSetModifyBowtieDataLoading,
  doSetBowtieData,
  doSetLoading,
};

export default diagramActions;
