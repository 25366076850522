import { Handle, HandleType, Position } from '@xyflow/react';
import { memo } from 'react';

export interface HandlesProps {
  topHandleId?: string;
  topHandleType?: HandleType;
  bottomHandleId?: string;
  bottomHandleType?: HandleType;
  leftHandleId?: string;
  leftHandleType?: HandleType;
  rightHandleId?: string;
  rightHandleType?: HandleType;
}

/**
 * Component that renders handles for a node in a flow diagram.
 *
 * @component
 * @param {HandlesProps} props - The properties for the handles.
 * @param {string} props.topHandleId - The ID for the top handle.
 * @param {string} props.topHandleType - The type for the top handle.
 * @param {string} props.bottomHandleId - The ID for the bottom handle.
 * @param {string} props.bottomHandleType - The type for the bottom handle.
 * @param {string} props.leftHandleId - The ID for the left handle.
 * @param {string} props.leftHandleType - The type for the left handle.
 * @param {string} props.rightHandleId - The ID for the right handle.
 * @param {string} props.rightHandleType - The type for the right handle.
 *
 * @returns {JSX.Element} The rendered handles.
 */
const Handles = (props: HandlesProps): JSX.Element => {
  const {
    topHandleId,
    topHandleType,
    bottomHandleId,
    bottomHandleType,
    leftHandleId,
    leftHandleType,
    rightHandleId,
    rightHandleType,
  } = props;

  return (
    <>
      {topHandleId && topHandleType && <Handle id={topHandleId} type={topHandleType} position={Position.Top} />}

      {bottomHandleId && bottomHandleType && (
        <Handle id={bottomHandleId} type={bottomHandleType} position={Position.Bottom} />
      )}

      {leftHandleId && leftHandleType && <Handle id={leftHandleId} type={leftHandleType} position={Position.Left} />}

      {rightHandleId && rightHandleType && (
        <Handle id={rightHandleId} type={rightHandleType} position={Position.Right} />
      )}
    </>
  );
};

export default memo(Handles);
