import Controls, { ContainerType } from '../controls.component';
import LoadingPreview from '../loading-preview';

interface PreventativeControlsProps {
  isLoading: boolean;
}

const message = 'Preventative controls will be displayed here';

const PreventativeControls = ({ isLoading }: PreventativeControlsProps) => {
  return (
    <Controls id="preventative-controls-container" type={ContainerType.PREVENTATIVE_CONTROLS}>
      {isLoading ? <LoadingPreview message={message} /> : message}
    </Controls>
  );
};

export default PreventativeControls;
