import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node, NodeProps, useReactFlow } from '@xyflow/react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { cn } from '../../../helpers/util';
import { useDiagramContext } from '../../context/diagram.context';
import { DiagramContextState } from '../../context/diagram.store';
import { LeafNodeTypes, MutableNodeTypes } from '../../util/node-util';
import Handles, { HandlesProps } from './handles.component';
import LinkIcon from './icons/link-icon.component';
import UnlinkIcon from './icons/unlink-icon.component';

export type BaseNodeData = {
  label?: string;
  className?: string;
  iconClassName?: string;
  globalIconClassName?: string;
  rowIndex?: number;
  editMode?: boolean;
  showRecordIndicators?: boolean;
  showLinkActionIcon?: boolean;
  showUnlinkActionIcon?: boolean;
  draft?: boolean;
  global?: boolean;
  handles?: HandlesProps;
};
type BaseNodeType = Node<BaseNodeData>;
type BaseNodeProps = NodeProps<BaseNodeType>;

const selector = (state: DiagramContextState) => ({
  updateNode: state.updateNode,
  removeNode: state.removeNode,
});

/**
 * BaseNode component represents a node in a flow diagram with optional edit mode and various indicators.
 *
 * @component
 * @param {BaseNodeProps} data - The properties for the BaseNode component.
 * @param {string} data.label - The label of the node.
 * @param {string} [data.className] - Additional class names for the node container.
 * @param {string} [data.iconClassName] - Class names for the action icons.
 * @param {string} [data.globalIconClassName] - Class names for the global indicator icon.
 * @param {boolean} [data.editMode=false] - Flag to enable edit mode.
 * @param {boolean} [data.showRecordIndicators=false] - Flag to show record indicators.
 * @param {boolean} [data.draft=false] - Flag to indicate if the node is a draft.
 * @param {boolean} [data.global=false] - Flag to indicate if the node is global.
 * @param {object} [data.handles={}] - Configuration for edge connection targets.
 *
 * @example
 * <BaseNode data={nodeData} />
 *
 * @returns {JSX.Element} The rendered BaseNode component.
 */
const BaseNode = ({ id, type, data }: BaseNodeProps): JSX.Element => {
  const {
    label,
    className,
    iconClassName,
    globalIconClassName,
    rowIndex,
    editMode = false,
    showRecordIndicators = false,
    showLinkActionIcon = false,
    showUnlinkActionIcon = false,
    draft = false,
    global = false,
    handles = {},
  } = data;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const { updateNodeData } = useReactFlow();
  const { updateNode, removeNode } = useDiagramContext(useShallow(selector));

  useEffect(() => {
    if (editMode === true && inputRef.current) {
      // Using only autoFocus on the textarea places the cursor at the beginning of the text
      // (https://github.com/facebook/react/issues/14125)
      const defaultValue = label ?? '';
      inputRef.current.defaultValue = defaultValue;
      inputRef.current.setSelectionRange(defaultValue.length, defaultValue.length);
    }
  }, [editMode]);

  const handleKeyDownEvent = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const updatedLabel = inputRef.current?.value.trim() ?? label!;
        updateNode(id, type as MutableNodeTypes, { label: updatedLabel }, rowIndex);
      } else if (event.code === 'Escape') {
        updateNodeData(id, { editMode: false });
      }
    },
    [updateNode, updateNodeData, id, label]
  );

  const handleUnlinkAction = useCallback(() => {
    removeNode(id, type as LeafNodeTypes, rowIndex);
  }, []);

  return (
    <div
      className={cn(
        'nopan nodrag bt-group bt-flex bt-h-14 bt-w-40 bt-items-center bt-justify-center bt-rounded-md bt-border bt-border-gray-2 bt-p-2 bt-text-xs bt-shadow-md',
        className,
        { 'bt-border-dashed bt-bg-transparent': editMode }
      )}
    >
      {!editMode && <div title={label}>{label}</div>}

      {editMode && (
        <textarea
          autoFocus
          ref={inputRef}
          onKeyDown={handleKeyDownEvent}
          className="nopan nodrag bt-h-full bt-w-full bt-resize-none bt-outline-none"
        />
      )}

      {/* edge connection targets */}
      <Handles {...handles} />

      {/* draft and global record indicators */}
      {showRecordIndicators && global && (
        <FontAwesomeIcon
          icon={faGlobe}
          className={cn('bt-absolute -bt-bottom-1 -bt-left-1 bt-h-3 bt-w-3', globalIconClassName)}
          title="Global control"
        />
      )}
      {showRecordIndicators && !global && draft && (
        <div
          title="Draft"
          className={cn('bt-absolute -bt-bottom-1 -bt-left-1 bt-h-3 bt-w-3 bt-rounded-full bt-border-2', className)}
        />
      )}
      {/* on hover action icons */}
      {showLinkActionIcon && (
        <div
          title="Open in Viking"
          className={cn(
            'bt-invisible bt-absolute -bt-right-2 -bt-top-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
        >
          <LinkIcon className={iconClassName} />
        </div>
      )}
      {showUnlinkActionIcon && (
        <div
          title="Unlink"
          className={cn(
            'bt-invisible bt-absolute -bt-bottom-2 -bt-right-2 bt-cursor-pointer group-focus-within:bt-visible group-hover:bt-visible'
          )}
          onClick={handleUnlinkAction}
        >
          <UnlinkIcon className={iconClassName} />
        </div>
      )}
    </div>
  );
};

export default memo(BaseNode);
