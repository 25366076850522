import { applyEdgeChanges, applyNodeChanges, OnEdgesChange, OnNodesChange, type Edge, type Node } from '@xyflow/react';
import { createStore } from 'zustand';
import { ControlDiagramNode, DiagramConfiguration } from '../@types/diagram';
import { generateNodesAndEdges } from '../util/node-edge-generator';
import { LeafNodeTypes, MutableNodeTypes } from '../util/node-util';

export interface DiagramState extends DiagramConfiguration {
  nodes: Array<Node>;
  edges: Array<Edge>;
}

interface DiagramActions {
  // config update actions
  addNode: (type: LeafNodeTypes, nodeData: ControlDiagramNode, rowIndex?: number) => void;
  updateNode: (id: string, type: MutableNodeTypes, data: Partial<ControlDiagramNode>, rowIndex?: number) => void;
  removeNode: (id: string, type: LeafNodeTypes, rowIndex?: number) => void;
  // flow diagram actions/handlers
  setNodes: (nodes: Array<Node>) => void;
  setEdges: (edges: Array<Edge>) => void;
  onNodesChange: OnNodesChange<Node>;
  onEdgesChange: OnEdgesChange<Edge>;
}

export type DiagramContextState = DiagramState & DiagramActions;

const initialState: DiagramState = {
  mue: { id: 'mue', label: 'Unwanted Event' },
  causes: [],
  consequences: [],
  nodes: [], // flow diagram nodes
  edges: [], // flow diagram edges
};

/**
 * Creates and initializes a diagram context store with the provided initial properties.
 * The store manages the state and actions for a flow diagram, including nodes, edges,
 * causes, consequences, and their associated controls.
 *
 * @param initProps - Optional initial properties to override the default diagram state
 *
 * @returns A store object containing the diagram state and the following actions:
 * - `setNodes`: Updates the diagram nodes
 * - `setEdges`: Updates the diagram edges
 * - `onNodesChange`: Handles node changes in the diagram
 * - `onEdgesChange`: Handles edge changes in the diagram
 * - `addNode`: Adds a new node to the diagram (cause, consequence, or control)
 * - `updateNode`: Updates an existing node in the diagram
 * - `removeNode`: Removes a node from the diagram
 */
export function createDiagramContextStore(initProps?: Partial<DiagramState>) {
  return createStore<DiagramContextState>()((set, get) => ({
    ...initialState,
    ...initProps,
    // flow diagram actions
    setNodes: (nodes) => set({ nodes }),
    setEdges: (edges) => set({ edges }),
    onNodesChange: (changes) => set({ nodes: applyNodeChanges(changes, get().nodes) }),
    onEdgesChange: (changes) => set({ edges: applyEdgeChanges(changes, get().edges) }),
    // diagram config actions
    addNode: (type, nodeData, rowIndex) =>
      set((state) => {
        let { causes, consequences } = state;

        if (type === 'cause-node') {
          causes = [...causes, { ...nodeData, controls: [] }];
        } else if (type === 'consequence-node') {
          consequences = [...consequences, { ...nodeData, controls: [] }];
        } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
          consequences = consequences.map((consequence, index) => {
            if (index === rowIndex) {
              return { ...consequence, controls: [...consequence.controls, nodeData] };
            }
            return consequence;
          });
        } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
          causes = causes.map((cause, index) => {
            if (index === rowIndex) {
              return { ...cause, controls: [...cause.controls, nodeData] };
            }
            return cause;
          });
        }

        // update flow diagram nodes and edges
        const { nodes, edges } = generateNodesAndEdges({ mue: state.mue, hazard: state.hazard, causes, consequences });

        return {
          causes,
          consequences,
          nodes,
          edges,
        };
      }),
    updateNode: (id, type, nodeData, rowIndex) =>
      set((state) => {
        let { mue, hazard, causes, consequences, nodes } = state;

        if (id === hazard?.id) {
          hazard = { ...hazard, ...nodeData };
        } else if (id === mue.id) {
          mue = { ...mue, ...nodeData };
        } else if (type === 'cause-node') {
          causes = causes.map((cause) => (cause.id === id ? { ...cause, ...nodeData } : cause));
        } else if (type === 'consequence-node') {
          consequences = consequences.map((consequence) =>
            consequence.id === id ? { ...consequence, ...nodeData } : consequence
          );
        } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
          consequences = consequences.map((consequence, index) => {
            if (index === rowIndex) {
              return {
                ...consequence,
                controls: consequence.controls.map((control) =>
                  control.id === id ? { ...control, ...nodeData } : control
                ),
              };
            }
            return consequence;
          });
        } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
          causes = causes.map((cause, index) => {
            if (index === rowIndex) {
              return {
                ...cause,
                controls: cause.controls.map((control) => (control.id === id ? { ...control, ...nodeData } : control)),
              };
            }
            return cause;
          });
        }

        // update flow diagram node
        nodes = nodes.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, ...nodeData, editMode: false } } : node
        );

        return {
          mue,
          hazard,
          causes,
          consequences,
          nodes,
        };
      }),
    removeNode: (id, type, rowIndex) =>
      set((state) => {
        let { causes, consequences } = state;

        if (type === 'cause-node') {
          causes = causes.filter((cause) => cause.id !== id);
        } else if (type === 'consequence-node') {
          consequences = consequences.filter((consequence) => consequence.id !== id);
        } else if (type === 'mitigating-control-node' && rowIndex !== undefined) {
          consequences = consequences.map((consequence, index) => {
            if (index === rowIndex) {
              return { ...consequence, controls: consequence.controls.filter((control) => control.id !== id) };
            }
            return consequence;
          });
        } else if (type === 'preventative-control-node' && rowIndex !== undefined) {
          causes = causes.map((cause, index) => {
            if (index === rowIndex) {
              return { ...cause, controls: cause.controls.filter((control) => control.id !== id) };
            }
            return cause;
          });
        }

        // update flow diagram nodes and edges
        const { nodes, edges } = generateNodesAndEdges({ mue: state.mue, hazard: state.hazard, causes, consequences });

        return {
          causes,
          consequences,
          nodes,
          edges,
        };
      }),
  }));
}
