import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import BaseNode, { BaseNodeData } from './base-node.component';
import { HandlesProps } from './handles.component';

type ConsequenceNodeData = BaseNodeData;
export type ConsequenceNodeType = Node<ConsequenceNodeData>;
type ConsequenceNodeProps = NodeProps<ConsequenceNodeType>;

const className = 'bt-bg-consequences-box-bg bt-border-consequences-box-border bt-text-consequences-box-fg';
const iconClassName = 'bt-text-consequences-box-bg bt-stroke-consequences-box-fg';
const handles: HandlesProps = { leftHandleId: 'left', leftHandleType: 'source' };
const staticData = { className, iconClassName, handles, showLinkActionIcon: true };

/**
 * A component that represents a consequence node in a flow diagram.
 * Extends the base node component with consequence-specific functionality.
 *
 * @component
 * @param {ConsequenceNodeProps} props - The properties for the consequence node component
 * @returns {JSX.Element} A rendered consequence node component
 *
 * @example
 * ```tsx
 * <ConsequenceNode
 *   id="consequence-1"
 *   data={{ label: "Root Consequence" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const ConsequenceNode = (props: ConsequenceNodeProps): JSX.Element => {
  const data = useMemo(() => {
    return { ...props.data, ...staticData };
  }, [props.data]);

  return <BaseNode {...props} data={data} />;
};

export default ConsequenceNode;
