import {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BowtieAddonConfig } from '../../@types/api.types';
import {
  AddonMappingDto,
  BackgroundJobDetails,
  CreateAndStartBowtieRecordsCreationJobApiResponse,
  EntityResponseDtoAddonResultDto,
  GetAddonMappingsApiResponse,
  GetBackgroundJobDetailsApiResponse,
  GetSimpleOptionsForFieldApiResponse,
  GetSimpleRecordApiResponse,
  SimpleRecordDto,
  v4Api,
} from '../generated/v4-api';

type Definitions = DefinitionsFromApi<typeof v4Api>;
type TagTypes = TagTypesFromApi<typeof v4Api>;

type TransformedCreateAndStartBowtieRecordsCreationJobResultType = BackgroundJobDetails | undefined;
type TransformedGetAddon1ResultType = BowtieAddonConfig;
type TransformedGetAddonMappingsResultType = AddonMappingDto;
type TransformedGetBackgroundJobDetailsResultType = BackgroundJobDetails | undefined;
type TransformedGetSimpleOptionsForFieldResultType = Array<string>;
type TransformedGetSimpleRecordResultType = SimpleRecordDto | undefined;

type UpdatedDefinitions = {
  createAndStartBowtieRecordsCreationJob: OverrideResultType<
    Definitions['createAndStartBowtieRecordsCreationJob'],
    TransformedCreateAndStartBowtieRecordsCreationJobResultType
  >;

  getAddon1: OverrideResultType<Definitions['getAddon1'], TransformedGetAddon1ResultType>;
  getAddonMappings: OverrideResultType<Definitions['getAddonMappings'], TransformedGetAddonMappingsResultType>;

  getBackgroundJobDetails: OverrideResultType<
    Definitions['getBackgroundJobDetails'],
    TransformedGetBackgroundJobDetailsResultType
  >;

  getSimpleOptionsForField: OverrideResultType<
    Definitions['getSimpleOptionsForField'],
    TransformedGetSimpleOptionsForFieldResultType
  >;

  getSimpleRecord: OverrideResultType<Definitions['getSimpleRecord'], TransformedGetSimpleRecordResultType>;
};

export const enhancedV4Api = v4Api.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  endpoints: {
    createAndStartBowtieRecordsCreationJob: {
      transformResponse: (response: CreateAndStartBowtieRecordsCreationJobApiResponse) => {
        return response.result;
      },
    },
    getAddon1: {
      query: (queryArg) => ({
        url: `/v4/add-ons/${queryArg.id}`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: ({
        result: { result } = {},
      }: EntityResponseDtoAddonResultDto): TransformedGetAddon1ResultType => {
        const bowtieConfiguration = JSON.parse(result?.jsonObject ?? '{}');
        return {
          ...result,
          bowtieConfiguration,
        };
      },
    },
    getAddonMappings: {
      query: () => ({
        url: `/v4/add-ons/mappings?filter=name:eq:bowtie`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: (response: GetAddonMappingsApiResponse): TransformedGetAddonMappingsResultType => {
        return response!.result![0];
      },
    },
    getBackgroundJobDetails: {
      transformResponse: (response: GetBackgroundJobDetailsApiResponse) => {
        return response.result;
      },
    },
    getSimpleOptionsForField: {
      transformResponse: (response: GetSimpleOptionsForFieldApiResponse) => {
        return response.items?.map((item) => item.caption!) ?? [];
      },
    },
    getSimpleRecord: {
      query: (queryArg) => ({
        url: `/v4/records/simple/${queryArg.id}`,
        validateStatus: (response, result) => response.status === 200 && result?.result,
      }),
      transformResponse: (response: GetSimpleRecordApiResponse) => {
        return response.result?.results?.[0];
      },
    },
  },
});

export const {
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetAddon1Query: useBowtieAddonQuery,
  useGetAddonMappingsQuery: useBowtieMappingQuery,
  useGetBackgroundJobDetailsQuery,
  useLazyGetSimpleOptionsForFieldQuery,
  useGetSimpleRecordQuery,
} = enhancedV4Api;
