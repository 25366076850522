import { memo } from 'react';

interface PlusIconProps {
  className?: string;
  width?: number;
  height?: number;
}

/**
 * PlusIcon component renders an SVG icon of a plus sign inside a circle.
 *
 * @returns {JSX.Element} The rendered SVG icon.
 *
 * @component
 * @example
 * // Usage example:
 * <PlusIcon />
 *
 * @remarks
 * The SVG icon has a viewBox of "0 0 24 24" and uses the "bt-fill-cline" class for styling.
 */
const PlusIcon = ({ width = 20, height = 20, className }: PlusIconProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} className={className}>
      {/* Outer circle with gray border and white fill */}
      <circle cx="12" cy="12" r="10" className="bt-fill-current" />
      {/* Plus sign in the center */}
      <path d="M11 11V7H13V11H17V13H13V17H11V13H7V11H11Z" strokeWidth={0.5} />
    </svg>
  );
};

export default memo(PlusIcon);
