import { cn } from '../../../../../helpers/util';
import { ContainerType } from '../../controls.component';
import { CriticalControlType, EffectiveControlType } from '../../multi-line-container/multi-element-line';
import { resolveIconClassName } from '../styles-util';

interface UnlinkIconProps {
  type: ContainerType;
  criticalControlType?: CriticalControlType;
  effectiveControlType?: EffectiveControlType;
  width?: number;
  height?: number;
}

export default function UnlinkIcon({
  width = 20,
  height = 20,
  type,
  criticalControlType,
  effectiveControlType,
}: UnlinkIconProps) {
  const className = resolveIconClassName(type, criticalControlType, effectiveControlType);

  return (
    <svg width={width} height={height} viewBox="0 0 16 16">
      <svg className={cn(className, 'bt-fill-current')}>
        <circle cx="8" cy="8" r="7" strokeWidth="0.75" />
      </svg>
      <svg className="bt-fill-current">
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  );
}
