import { uniq } from 'lodash';
import { DynamicFormSettings } from '../../@types/form-data-types';
import { v4Api } from '../../api/generated/v4-api';
import { EndPointSettings, keys } from '../../helpers/config';
import Request from '../../helpers/request';
import { buildUrl } from '../../helpers/urlManager';
import {
  BowtieBasicRecord,
  BowtieStateCauseData,
  BowtieStateConsequenceData,
  BowtieStateData,
} from '../../services/bowtie-data-types';
import { RecordResult } from '../../services/record-data-types';
import { AppDispatch } from '../hooks';

interface RiskScenarioPayloadType {
  formId: number;
  formName: string;
  status: string;
  linkedRecordId: number;
  fields: Record<string, unknown>;
  preventative?: boolean;
  mitigating?: boolean;
}

export default class DiagramService {
  private readonly env: string;
  private readonly envConfig: EndPointSettings;

  constructor() {
    this.env = 'test';
    this.envConfig = keys['test'];
  }

  addRiskScenario(form: DynamicFormSettings, labelField: string, labelValue: string, draftStatus?: string) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple` }, {});

    let status = 'Draft';
    if (draftStatus) {
      status = draftStatus;
    } else if (form.workflowSteps && form.workflowSteps.length > 0) {
      status = form.workflowSteps.find((step) => step.draft === true)?.label ?? 'Default';
    }

    return Request.doRequest({
      url: requestUrl,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: [
        {
          formId: form.id,
          hierarchies: {},
          fields: {
            [labelField]: labelValue,
          },
          status: status,
        },
      ],
    });
  }

  async updateRiskScenarioHazard(
    riskScenarioRecord: RecordResult,
    hazardFieldLabel: string,
    hazardFieldValue: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: riskScenarioRecord.id as number,
        params: {},
        simpleRecordDto: {
          formId: riskScenarioRecord.formId,
          formName: riskScenarioRecord.formName,
          status: riskScenarioRecord.status,
          id: riskScenarioRecord.id,
          fields: {
            [hazardFieldLabel]: hazardFieldValue,
          },
        },
      })
    ).unwrap();
    return result;
  }

  updateRiskScenarioRecord(riskScenarioId: number, riskScenarioPayload: RiskScenarioPayloadType) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${riskScenarioId}` }, {});

    const editableFieldKey: string | undefined = Object.keys(riskScenarioPayload.fields).find((fieldKey: string) => {
      if (riskScenarioPayload.mitigating) {
        return fieldKey === 'Mitigating Controls';
      }

      if (riskScenarioPayload.preventative) {
        return fieldKey === 'Preventative Controls';
      }

      return fieldKey === 'Preventative Controls';
    });

    const fieldValue: Array<string> = editableFieldKey
      ? (riskScenarioPayload.fields[editableFieldKey] as string[])
      : [];

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
      data: {
        formId: riskScenarioPayload.formId,
        formName: riskScenarioPayload.formName,
        status: riskScenarioPayload.status,
        id: riskScenarioId,
        fields: {
          [riskScenarioPayload.mitigating ? 'Mitigating Controls' : 'Preventative Controls']: editableFieldKey
            ? uniq([...fieldValue, String(riskScenarioPayload.linkedRecordId)])
            : [String(riskScenarioPayload.linkedRecordId)],
        },
      },
    });
  }

  async updateRiskScenarioField(bowtieData: BowtieStateData, value: string, dispatch: AppDispatch) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: bowtieData.scenarioRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: bowtieData.scenarioRecord.id,
          formId: bowtieData.scenarioRecord.formId,
          formName: bowtieData.scenarioRecord.formName,
          status: bowtieData.scenarioRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.main.scenarioCaptionField]: value,
          },
        },
      })
    );
    return result;
  }

  async updateCause(
    bowtieData: BowtieStateData,
    causeRecord: BowtieStateCauseData,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: causeRecord.uuid as number,
        params: {},
        simpleRecordDto: {
          id: causeRecord.uuid,
          formId: bowtieData.bowtieConfiguration.causes.formId,
          status: causeRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.causes.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updateConsequence(
    bowtieData: BowtieStateData,
    consequenceRecord: BowtieStateConsequenceData,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: consequenceRecord.uuid as number,
        params: {},
        simpleRecordDto: {
          id: consequenceRecord.uuid,
          formId: bowtieData.bowtieConfiguration.consequences.formId,
          status: consequenceRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.consequences.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updatePreventativeControl(
    bowtieData: BowtieStateData,
    preventativeControlRecord: BowtieBasicRecord,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: preventativeControlRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: preventativeControlRecord.id,
          formId: bowtieData.bowtieConfiguration.controls.formId,
          status: preventativeControlRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.controls.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  async updateMitigatingControl(
    bowtieData: BowtieStateData,
    mitigatingControlRecord: BowtieBasicRecord,
    value: string,
    dispatch: AppDispatch
  ) {
    const result = await dispatch(
      v4Api.endpoints.patchSimple.initiate({
        id: mitigatingControlRecord.id as number,
        params: {},
        simpleRecordDto: {
          id: mitigatingControlRecord.id,
          formId: bowtieData.bowtieConfiguration.controls.formId,
          status: mitigatingControlRecord.status,
          fields: {
            [bowtieData.bowtieConfiguration.controls.captionField]: value,
          },
        },
      })
    ).unwrap();
    return result;
  }

  doUpdateRecord(recordId: number, recordPayload: RecordResult) {
    const authorizationHeader = localStorage.getItem('idToken');
    const requestUrl = buildUrl({ env: this.env, endpoint: `records/simple/${recordId}` }, {});

    return Request.doRequest({
      url: requestUrl,
      method: 'PATCH',
      data: recordPayload,
      headers: {
        Authorization: 'Bearer ' + authorizationHeader,
        'x-api-key': this.envConfig.apiKey,
      },
    });
  }
}
