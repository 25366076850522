import { BowtieStateConsequenceData, BowtieStateData } from '../../../../services/bowtie-data-types';
import { ContainerType } from '../controls.component';
import GeneralContainer from '../general-container';
import MultiLineContainer, { CONTAINER_ALIGNMENT } from '../multi-line-container/multi-line-container';

interface MitigatingControlsExistingProps {
  bowtieData: BowtieStateData;
}

const MitigatingControlsExisting = ({ bowtieData }: MitigatingControlsExistingProps) => {
  const mitigatingControlsLines = bowtieData.consequences
    ? bowtieData.consequences.map((consequence: BowtieStateConsequenceData, index) => {
        return {
          id: `${consequence.uuid}_${index}`,
          childControls: consequence.mitigatingControls,
          parentId: consequence.uuid,
        };
      })
    : [];

  return (
    <GeneralContainer id="mitigating-controls-container" type={ContainerType.MITIGATING_CONTROLS}>
      <MultiLineContainer
        id="mitigating_control"
        type={ContainerType.MITIGATING_CONTROLS}
        mode="multi"
        controlLines={mitigatingControlsLines}
        formId={bowtieData.bowtieConfiguration.controls.formId}
        alignment={CONTAINER_ALIGNMENT.LEFT}
      />
    </GeneralContainer>
  );
};

export default MitigatingControlsExisting;
