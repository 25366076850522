import React, { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import { generateNodesAndEdges } from '../util/node-edge-generator';
import { createDiagramContextStore, DiagramContextState, DiagramState } from './diagram.store';

type DiagramContextStore = ReturnType<typeof createDiagramContextStore>;

const DiagramContext = createContext<DiagramContextStore | undefined>(undefined);

type DiagramProviderProps = React.PropsWithChildren<DiagramState>;

export const DiagramProvider: React.FC<DiagramProviderProps> = ({
  children,
  ...props
}: DiagramProviderProps): JSX.Element => {
  const storeRef = useRef<DiagramContextStore>();

  if (!storeRef.current) {
    // Setup zustand store (https://zustand.docs.pmnd.rs/guides/initialize-state-with-props)
    const { nodes, edges } = generateNodesAndEdges(props);
    storeRef.current = createDiagramContextStore({ ...props, nodes, edges });
  }

  return <DiagramContext.Provider value={storeRef.current}>{children}</DiagramContext.Provider>;
};

// context consumer hook
export function useDiagramContext<T>(selector: (state: DiagramContextState) => T): T {
  const store = useContext(DiagramContext);

  if (store === undefined) {
    throw new Error('useDiagramContext must be used within DiagramProvider');
  }

  return useStore(store, selector);
}
