import { createSelector } from 'reselect';

const selectRaw = (state) => state.user;

const selectUser = createSelector([selectRaw], (user) => user.user);

const userSelectors = {
  selectUser,
};

export default userSelectors;
