import { OdinIcon } from '@myosh/odin-components';
import DOMPurify from 'dompurify';
import { forwardRef, Ref, useImperativeHandle, useState } from 'react';

interface AIAnalysisSuggestionContainerProps {}

export interface AIAnalysisSuggestionContainerRef {
  show: (text: string) => void;
}

const AIAnalysisSuggestionContainer = (
  {}: AIAnalysisSuggestionContainerProps,
  ref: Ref<AIAnalysisSuggestionContainerRef>
): JSX.Element => {
  const [suggestion, setSuggestion] = useState<string>();

  const show = (suggestion: string) => {
    setSuggestion(suggestion);
  };

  useImperativeHandle(ref, () => {
    return { show };
  });

  const handleClickClose = () => {
    setSuggestion(undefined);
  };

  if (!suggestion) {
    return <></>;
  }

  return (
    <div className="bt-mb-4 bt-ml-16 bt-mt-4 bt-flex bt-h-fit bt-max-h-[200px] bt-w-[75%] bt-flex-col bt-rounded bt-border bt-border-gray-1 bt-bg-mono-1 bt-p-4">
      <div className="bt-flex bt-cursor-pointer bt-flex-row bt-items-center bt-justify-between">
        <label className="bt-font-bold">AI Analysis</label>
        <div onClick={handleClickClose}>
          <OdinIcon icon="Close" />
        </div>
      </div>
      <hr className="bt-mb-4 bt-mt-4 bt-border-gray-4" />
      <div className="bt-max-h=[200px] bt-flex bt-h-full bt-overflow-y-auto bt-overflow-x-hidden bt-whitespace-pre-line bt-text-wrap bt-text-sm">
        <div dangerouslySetInnerHTML={createMarkup(suggestion)} />
      </div>
    </div>
  );
};

export default forwardRef(AIAnalysisSuggestionContainer);

// util
const createMarkup = (value: string) => {
  return { __html: DOMPurify.sanitize(value, { USE_PROFILES: { html: true } }) };
};
