import { Node, NodeProps } from '@xyflow/react';
import { memo, useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useDiagramContext } from '../../context/diagram.context';
import { DiagramContextState } from '../../context/diagram.store';
import AddCauseConsequenceButton from './add-cause-consequence-button.component';
import BaseContainerNode, { BaseContainerNodeData } from './base-container-node.component';

type CausesContainerNodeData = BaseContainerNodeData;
export type CausesContainerNodeType = Node<CausesContainerNodeData>;
type CausesContainerNodeProps = NodeProps<CausesContainerNodeType>;

export const id = crypto.randomUUID();
const label = 'Causes';
const className = 'bt-border-causes-border bt-bg-causes-bg bt-text-causes-fg bt-relative';
const data = { label, className };

const addButtonLabel = '+ Add Cause';
const addButtonClassName =
  'bt-text-causes-fg bt-mt-2 bt-flex bt-w-full bt-justify-center bt-border-2 bt-border-causes-border bt-bg-causes-bg bt-px-0 bt-py-2 bt-text-sm bt-text-causes-border hover:bt-bg-causes-border hover:bt-text-causes-box-fg';

const selector = (state: DiagramContextState) => ({
  addNode: state.addNode,
  hasCauses: state.causes.length > 0,
});

/**
 * A React functional component that renders a container node for Causes.
 *
 * This component is a wrapper around the `BaseContainerNode` and passes
 * all received props along with additional data to it.
 *
 * @component
 * @param {CausesContainerNodeProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered container node component.
 *
 * @example
 * ```tsx
 * <CausesContainerNode />
 * ```
 */
const CausesContainerNode = (props: CausesContainerNodeProps): JSX.Element => {
  const { addNode, hasCauses } = useDiagramContext(useShallow(selector));

  const handleAdd = useCallback(() => {
    addNode('cause-node', { id: crypto.randomUUID(), label: 'New' });
  }, []);

  return (
    <>
      <BaseContainerNode {...props} id={id} data={data} />
      <AddCauseConsequenceButton
        label={addButtonLabel}
        className={addButtonClassName}
        onClick={handleAdd}
        hasControls={hasCauses}
      />
    </>
  );
};

export default memo(CausesContainerNode);
