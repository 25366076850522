import { Controls, ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useShallow } from 'zustand/react/shallow';
import DiagramLoading from './components/container/diagram-loading.component';
import { useDiagramContext } from './context/diagram.context';
import { DiagramContextState } from './context/diagram.store';
import useDiagramHandlers from './hooks/use-diagram-handlers';
import { diagramEdgeTypes } from './util/edge-util';
import { diagramNodeTypes } from './util/node-util';

const selector = (state: DiagramContextState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
});

/**
 * Diagram component renders a full-screen diagram using ReactFlow.
 *
 * @returns {JSX.Element} The rendered diagram component.
 *
 * @component
 * @example
 * return (
 *   <Diagram />
 * )
 *
 * @see {@link https://reactflow.dev/|ReactFlow Documentation}
 */
const Diagram = (): JSX.Element => {
  const { handleNodeClick, handleClick, handleKeyDownEvent } = useDiagramHandlers();
  const { nodes, edges, onNodesChange, onEdgesChange } = useDiagramContext(useShallow(selector));

  return (
    <div className="bt-relative bt-flex bt-h-full bt-w-full">
      <DiagramLoading />
      <ReactFlow
        className="nowheel"
        nodes={nodes}
        edges={edges}
        nodeTypes={diagramNodeTypes}
        edgeTypes={diagramEdgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={handleNodeClick}
        onClick={handleClick}
        onKeyDown={handleKeyDownEvent}
        fitView
        panOnDrag
        edgesFocusable={false}
        nodesDraggable={false}
        nodesConnectable={false}
        nodesFocusable={false}
        draggable={false}
        elementsSelectable={false}
        zoomOnDoubleClick={false}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </div>
  );
};

export default Diagram;
