import { Button } from '@myosh/odin-components';
import { useEffect, useRef, useState } from 'react';
import { Id, toast } from 'react-toastify';

import {
  useCreateAndStartBowtieRecordsCreationJobMutation,
  useGetBackgroundJobDetailsQuery,
} from '../../api/enhanced/enhanced-v4-api';
import recordSelectors from '../../modules/diagram/diagramSelectors';
import { useAppSelector } from '../../modules/hooks';
import { BowtieStateCauseData, BowtieStateConsequenceData, BowtieStateData } from '../../services/bowtie-data-types';

// A button that creates the backed records for the AI generated diagram
const CreateRecordsButton = () => {
  const [jobId, setJobId] = useState<number>();
  const toastIdRef = useRef<Id>();
  const bowtieData: BowtieStateData = useAppSelector(recordSelectors.selectBowtieData);

  const [postCreateRecordsJob] = useCreateAndStartBowtieRecordsCreationJobMutation();

  const { data: { status, jobDetails } = {}, isError } = useGetBackgroundJobDetailsQuery(
    { id: jobId! },
    {
      skip: !jobId,
      pollingInterval: jobId ? 5000 : 0, // 5 seconds polling interval
    }
  );

  useEffect(() => {
    if ('FINISHED' === status && jobDetails && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: 'Diagram created successfully',
        type: 'info',
        isLoading: false,
        autoClose: 2000,
      });

      const parsedJobDetails = JSON.parse(jobDetails);

      if (parsedJobDetails?.recordId) {
        // Reload the page and load the newly created bowtie diagram
        const params = new URLSearchParams({ records: String(parsedJobDetails.recordId) });
        setTimeout(() => window.location.replace(`${window.location.origin}?${params}`), 2500);
      }
    } else if (('STAGED' === status || isError) && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: 'Unable to create diagram',
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });
    }
  }, [status, isError]);

  const handleClick = async () => {
    // notify user
    toastIdRef.current = toast.loading('Creating backend records...');

    // post records creation job
    const bowtieDto = createBowtieDto(bowtieData);

    const { id } = (await postCreateRecordsJob(bowtieDto).unwrap()) ?? {};

    setJobId(id);
  };

  return (
    <Button variant="alternative" onClick={handleClick} type="primary" disabled={Boolean(jobId)}>
      {'Create Backend Records'}
    </Button>
  );
};

export default CreateRecordsButton;

// util
const createBowtieDto = (bowtieData: BowtieStateData) => {
  return {
    bowtieDto: {
      hazard: bowtieData.hazard,
      riskScenario: bowtieData.scenario,
      causes: bowtieData.causes?.map((cause: BowtieStateCauseData) => ({
        cause: cause.value,
        controls: cause.preventativeControls?.map((control) => ({ name: control.value })),
      })),
      consequences: bowtieData.consequences?.map((consequence: BowtieStateConsequenceData) => ({
        consequence: consequence.value,
        controls: consequence.mitigatingControls?.map((control) => ({ name: control.value })),
      })),
    },
  };
};
