import { memo } from 'react';

/**
 * @component ControlLegend
 * @description
 * The `ControlLegend` component renders a legend for different control statuses.
 * It displays three types of controls: "Control", "Critical Control", and "Control Issues",
 * each represented by a colored box and a label.
 *
 * @returns {JSX.Element} A JSX element containing the control legend.
 *
 * @example
 * ```tsx
 * <ControlLegend />
 * ```
 */
const ControlLegend = () => {
  return (
    <div className="bt-flex bt-pr-3">
      <div className="bt-flex bt-gap-6">
        <div className="bt-flex bt-items-center bt-gap-2">
          <div
            id="critical"
            className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-text-wrap bt-rounded-[0.3rem] bt-bg-control-default"
          />
          <label>Control</label>
        </div>
        <div className="bt-flex bt-items-center bt-gap-2">
          <div
            id="critical"
            className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-rounded-[0.3rem] bt-bg-control-critical"
          />
          <label>Critical Control</label>
        </div>
        <div className="bt-flex bt-items-center bt-gap-2">
          <div
            id="non-critical"
            className="bt-h-[1.2rem] bt-w-[1.2rem] bt-cursor-auto bt-rounded-[0.3rem] bt-bg-control-issue"
          />
          <label>Control Issues</label>
        </div>
      </div>
    </div>
  );
};

export default memo(ControlLegend);
