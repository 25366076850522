import { Node, NodeProps } from '@xyflow/react';
import { useMemo } from 'react';
import { CriticalControlEnum, EffectiveControlEnum } from '../../util/node-util';
import BaseNode, { BaseNodeData } from './base-node.component';

type PreventativeControlNodeData = BaseNodeData & {
  criticalControlType?: CriticalControlEnum;
  effectiveControlType?: EffectiveControlEnum;
};
export type PreventativeControlNodeType = Node<PreventativeControlNodeData>;
type PreventativeControlNodeProps = NodeProps<PreventativeControlNodeType>;

const defaultClassName = 'bt-bg-pc-default-bg bt-border-pc-default-border bt-text-pc-default-fg';
const nonEffectiveClassName =
  'bt-bg-pc-non_critical-non_effective-bg bt-border-pc-non_critical-non_effective-border bt-text-pc-non_critical-non_effective-fg';
const criticalClassName =
  'bt-bg-pc-critical-effective-bg bt-border-pc-critical-effective-border bt-text-pc-critical-effective-fg';

const defaultIconClassName = 'bt-text-pc-default-bg bt-stroke-pc-default-fg';
const nonEffectiveIconClassName = 'bt-text-pc-non_critical-non_effective-bg bt-stroke-pc-non_critical-non_effective-fg';
const criticalIconClassName = 'bt-text-pc-critical-effective-bg bt-stroke-pc-critical-effective-fg';

const defaultGlobalIconClassName = 'bt-text-pc-default-border';
const nonEffectiveGlobalIconClassName = 'bt-text-pc-non_critical-non_effective-border';
const criticalGlobalIconClassName = 'bt-text-pc-critical-effective-border';

/**
 * A component that represents a preventative control node in a flow diagram.
 * Extends the base node component with preventative-control-specific functionality.
 *
 * @component
 * @param {PreventativeControlNodeProps} props - The properties for the preventative control node component
 * @returns {JSX.Element} A rendered preventative control node component
 *
 * @example
 * ```tsx
 * <PreventativeControlNode
 *   id="preventative control-1"
 *   data={{ label: "Preventative Control" }}
 *   position={{ x: 100, y: 100 }}
 * />
 * ```
 */
const PreventativeControlNode = (props: PreventativeControlNodeProps): JSX.Element => {
  const { criticalControlType, effectiveControlType } = props.data;

  const { className, iconClassName, globalIconClassName } = useMemo(() => {
    let className = defaultClassName;
    let iconClassName = defaultIconClassName;
    let globalIconClassName = defaultGlobalIconClassName;

    if (effectiveControlType === EffectiveControlEnum.NON_EFFECTIVE) {
      className = nonEffectiveClassName;
      iconClassName = nonEffectiveIconClassName;
      globalIconClassName = nonEffectiveGlobalIconClassName;
    } else if (criticalControlType === CriticalControlEnum.CRITICAL) {
      className = criticalClassName;
      iconClassName = criticalIconClassName;
      globalIconClassName = criticalGlobalIconClassName;
    }

    return { className, iconClassName, globalIconClassName };
  }, [criticalControlType, effectiveControlType]);

  const data = useMemo(() => {
    return {
      ...props.data,
      className,
      iconClassName,
      globalIconClassName,
      showRecordIndicators: true,
      showLinkActionIcon: true,
      showUnlinkActionIcon: true,
    };
  }, [props.data, className]);

  return <BaseNode {...props} data={data} />;
};

export default PreventativeControlNode;
