import { cn } from '../../../../helpers/util';
import { ContainerType } from '../controls.component';

interface ButtonProps {
  text: string;
  type: ContainerType;
  buttonClicked: () => void;
}

// The multi-line container add button
const Button = ({ text, type, buttonClicked }: ButtonProps) => {
  const transformedTextArray = text.split('');
  transformedTextArray.pop();
  const transformedText = transformedTextArray.join('');

  const className = cn(
    {
      'bt-border-causes-border bt-text-causes-border hover:bt-bg-causes-border hover:bt-text-causes-box-fg':
        type === ContainerType.CAUSES,
    },
    {
      'bt-border-consequences-border bt-text-consequences-border hover:bt-bg-consequences-border hover:bt-text-consequences-box-fg':
        type === ContainerType.CONSEQUENCES,
    }
  );

  return (
    <button
      className={cn(
        'bt-absolute -bt-bottom-12 bt-left-0 bt-z-[8999] bt-mt-2 bt-flex bt-w-full bt-items-center bt-justify-center bt-border-2 bt-px-0 bt-py-[6px] bt-text-sm bt-transition-all bt-duration-200',
        className
      )}
      onClick={buttonClicked}
    >
      <div className="bt-mr-1">+</div>
      <div>{transformedText}</div>
    </button>
  );
};

export default Button;
