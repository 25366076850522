import {
  DataSearchLocation,
  DropDownNoSelection,
  DropDownResult,
  isDropDownNoSelection,
  JsonDataWrapper,
  OdinDataRetrieval,
  OdinDataRetrievalOptions,
  OdinDataSender,
  DropDown as OdinDropDown,
} from '@myosh/odin-components';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetSimpleRecordQuery } from '../../api/enhanced/enhanced-v4-api';
import { useLazyGetSimpleRecords1Query } from '../../api/generated/v4-api';
import { useConfigurationContext } from '../../context/configuration.context';
import { useDiagramContext } from '../../context/diagram.context';
import { cn } from '../../helpers/util';

type DropDownValue = Record<string, unknown>;

/**
 * A dropdown component for the header section that displays and manages record selection.
 *
 * @component
 * @description
 * Renders a dropdown menu that allows users to:
 * - Display currently selected record
 * - Search and select records from an API
 * - Open selected records in a new window
 *
 * The component integrates with URL parameters to maintain state and uses the OdinDropDown
 * component for the actual dropdown functionality.
 *
 * @example
 * ```tsx
 * <RecordDropDown />
 * ```
 *
 * @returns {JSX.Element} A dropdown component wrapped in a fixed-width container
 */
const RecordDropDown = (): JSX.Element => {
  const [record, setRecord] = useState<DropDownValue>();
  const { isNewDiagram } = useDiagramContext();

  const {
    main: { formId, scenarioCaptionField },
  } = useConfigurationContext();

  const displayValueKey = useMemo(() => `fields[${scenarioCaptionField}]`, [scenarioCaptionField]);

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [getRecords] = useLazyGetSimpleRecords1Query();

  const { data, isFetching } = useGetSimpleRecordQuery(
    { id: Number(queryParams.get('records')), params: {} },
    { skip: isNewDiagram || !queryParams.has('records') }
  );

  if (!isFetching && data && !record) {
    // For the value to be set on the drop down, it must be in the format of DropDownResult and have 'value' and 'text' properties
    setRecord({ ...data, value: data.id, text: data.fields?.[scenarioCaptionField] });
  }

  const dataRetrieval: OdinDataRetrieval = useMemo(() => {
    return {
      getData: async (subscriber: OdinDataSender<JsonDataWrapper>, options?: OdinDataRetrievalOptions) => {
        if (options) {
          let filter;
          if (options.fieldFilters?.[displayValueKey]) {
            filter = `${scenarioCaptionField}:like:${options.fieldFilters[displayValueKey].value}`;
          }

          const records = await getRecords({
            formId,
            page: options.page ?? 1,
            pageSize: options.pageSize ?? 50,
            sort: [scenarioCaptionField],
            filter,
          }).unwrap();

          const data = records.result?.results ?? [];
          subscriber.sendData({ data });
        } else {
          subscriber.sendData();
        }
      },
    };
  }, []);

  const handleOnChange = (value?: DropDownResult | Array<DropDownResult> | DropDownNoSelection) => {
    if (value && !isDropDownNoSelection(value) && !Array.isArray(value)) {
      const params = new URLSearchParams({ records: String(value.value) });
      window.open(`${window.location.origin}?${params}`);

      // this is a workaround as the drop down will set the selected value internally,
      // and we need to keep the previous value
      if (isNewDiagram) {
        // clear selection for new diagrams
        setRecord({ noSelectionValue: true });
      } else {
        // reset selection for existing diagrams
        setRecord({ ...record });
      }
    }
  };

  const selectorStyles = cn('bt-text-base bt-font-bold bt-leading-9', {
    "before:bt-content-['New_Diagram']": isNewDiagram,
  });

  return (
    <div className="bt-min-w-80 bt-max-w-80">
      <OdinDropDown
        valueField="id"
        textField={displayValueKey}
        value={record as unknown as DropDownResult | DropDownNoSelection}
        onChange={handleOnChange}
        data={dataRetrieval}
        filterLocation={DataSearchLocation.Api}
        allowClear={false}
        preloadData
        shouldUseCreatePortal
        className="bt-h-12 bt-rounded bt-bg-gray-5"
        selectorStyles={selectorStyles}
      />
    </div>
  );
};

export default RecordDropDown;
