import { Node, NodeProps } from '@xyflow/react';
import { memo } from 'react';
import { cn } from '../../../helpers/util';

export type BaseContainerNodeData = {
  label: string;
  className?: string;
};
type BaseContainerNodeType = Node<BaseContainerNodeData>;
type BaseContainerNodeProps = NodeProps<BaseContainerNodeType>;

/**
 * BaseContainerNode component.
 *
 * This component represents a base container node used in the flow.
 * It renders a div with a dashed border and optional label.
 *
 * @component
 * @param {BaseContainerNodeProps} props - The properties for the BaseContainerNode component.
 * @param {BaseContainerNodeType} props.data - The data for the node.
 * @param {string} [props.data.label] - Optional label to be displayed inside the node.
 * @param {string} [props.data.className] - Optional additional class names to be applied to the node.
 * @returns {JSX.Element} The rendered BaseContainerNode component.
 *
 * @example
 * ```tsx
 * <BaseContainerNode data={{ label: 'Node Label', className: 'custom-class' }} />
 * ```
 */
const BaseContainerNode = ({ id, data }: BaseContainerNodeProps): JSX.Element => {
  const { label, className } = data;

  return (
    <div
      id={id}
      className={cn(
        'nopan nodrag bt-flex bt-h-full bt-w-full bt-justify-center bt-rounded-md bt-border-2 bt-border-dashed bt-px-2 bt-py-4',
        className
      )}
    >
      {label && <div className="bt-text-base bt-font-bold">{label}</div>}
    </div>
  );
};

export default memo(BaseContainerNode);
